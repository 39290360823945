import '../styles/SubFormQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import ReactDragListView from 'react-drag-listview';
import { Button, InputGroup } from 'react-bootstrap';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { uniq } from 'lodash';
import uuid  from 'uuid/v4';
import { isNullOrUndefined } from 'utils/utils';
import FormUtils from '../utils/FormUtils';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import FormComponent from '../FormComponent';
import { generateName, getQuestionText, getTextColor, initDataPoint } from '../utils/utils';
/* import { renderQuestions } from '../utils/qnrenderer'; */
import { getLocalization } from '../../../global/global';
import GenericModal from '../../Modals/GenericModal';
import { ConfirmationModal } from '../../Modals/ConfirmationModal';
import { validateSubForms } from '../utils/Validator';
import { getFormUtils } from '../utils/FormUtilsHolder';
import { updateScriptObject } from '../utils/ScriptUtils';
import TextInputComponent from './TextInputComponent';
import OriginalFormText from './OriginalFormText';

export interface Props {
  question: LooseObject;
  forms: FormInterface[];
  updateAnswer: (value: LooseObject) => void;
  dataPoint: DataPoint;
  formUtils: FormUtils;
  parentModel: LooseObject;
  clientPersist: ClientPersistInterface;
  isSubQuestion?: boolean;
  edit: boolean;
}

interface State {
  value: DataPoint[];
  subFormDataPoint: DataPoint;
  currentIndex: number;
  showSubForm: boolean;
  formName: string;
  subForm: FormInterface | undefined;
  confirmRemove: boolean;
  invalid: boolean;
  sortable: boolean;
  confirmCancel: boolean;
  localSPSSToId?: JSONInterface | undefined;
  obj: JSONInterface;
  colorCode?: {[key:string]: string};
  search: string;
  shownInSelectionList: string[];
  filtered: DataPoint[];
  recording: boolean;
  saving: boolean;
}

export default class SubFormQuestion extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    const { dataPoint, question, forms, formUtils } = this.props;
    const subForm = forms.find( f => f.ref === question.listItems.relation[0].ref);
    if (subForm) {
      subForm.responsiveLayout = formUtils.getModel().responsiveLayout;
    }
    const sf = getFormUtils(subForm);
    const sortable = sf && sf.getQuestion('positionQuestion') ? true : false;
    const value = dataPoint[question.id] ? this.sortSubforms(dataPoint[question.id], sortable) : [];
    const page = subForm?.questionPage ? subForm.questionPage[0] : undefined;
    let shownInSelectionList: string[] = [];
    if (page && page.question) {
      const questions = sf.getQuestions();
      shownInSelectionList = Object.keys(questions).map(
        key => questions[key]
      ).filter(qn => qn.showInSelectionList).map(qn => qn.id);
    }
    this.state = {
      value,
      subFormDataPoint: {} as DataPoint,
      currentIndex: -1,
      showSubForm: false,
      formName: subForm ? subForm.name : question.listItems.relation[0].text,
      subForm: subForm,
      confirmRemove: false,
      invalid: false,
      sortable: sortable,
      confirmCancel: false,
      search: '',
      filtered: value,
      shownInSelectionList,
      recording: false,
      saving: false,
      ...this.setObjValues(sf, subForm?.colorCodeScript || '')
    };
  }

  @bind
  private setObjValues(formUtils: FormUtils, script: string): Pick<State, 'localSPSSToId' | 'obj'> {
    const localSPSSToId: LooseObject = {}; // mapping for _spss -> id
    const obj = {};
    if (script) {
      const allVariables: string[] = uniq(script.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));
      for (const v of allVariables) {
        if (v.indexOf('.') !== -1) { // this may be summing a table, a lookup value.
          const prefix = v.substr(0, v.indexOf('.'));
          const questionId = formUtils.getQuestionId(prefix);
          localSPSSToId[v] = questionId;
        } else {
          const id = formUtils.getQuestionId(v);
          localSPSSToId[v] = id;
        }
        obj[v] = '';
      }
    }
    return { localSPSSToId, obj };
  }

  @bind
  private updateSubFormAnswer(answer: DataPoint) {
    if (!isNullOrUndefined(answer['recording'])) {
      this.setState({ recording: answer.recording });
    } else {
      this.setState((prevState) =>
        ({ subFormDataPoint: {...prevState.subFormDataPoint, ...answer} }));
    }
  }

  @bind
  private saveSubFormInstance() {
    const { currentIndex, subFormDataPoint, value, filtered, sortable, recording } = this.state;
    if (recording) {
      this.setState({ confirmCancel: true, saving: true });
      return;
    }
    const { edit, question } = this.props;
    const newValue = [...value];
    const newFiltered = [...filtered];
    if (subFormDataPoint && edit) {
      const data = {...subFormDataPoint};
      if (data['Name'] === undefined) {
        data['Name'] = generateName();
      }
      data['modified'] = Date.now();
      delete data['freezedQuestions'];

      if (!question.static) {
        if (currentIndex === -1) {
          newFiltered.push(data);
          newValue.push(data);
        } else {
          newFiltered.splice(currentIndex, 1, data);
          newValue.every((v, i) => {
            if (v.id === subFormDataPoint.id) {
              newValue[i] = subFormDataPoint;
              return false;
            }
            return true;
          });
        }
      } else {
        if (currentIndex === -1) {
          newValue.push(data);
        } else {
          newValue.splice(currentIndex, 1,  data);
        }
      }

      const { clientPersist, forms } = this.props;
      const invalidObj = validateSubForms(question, newValue, forms, clientPersist);
      const invalid = invalidObj !== undefined;
      this.setState({
        invalid,
        currentIndex: -1,
        subFormDataPoint: {} as DataPoint,
        value: this.sortSubforms(newValue, sortable),
        filtered: this.sortSubforms(newFiltered, sortable),
        showSubForm: false
      }, () => {
        this.setAnswer();
        if (this.state.search) {
          this.filterSubforms();
        }
      });
    } else {
      this.setState({
        currentIndex: -1,
        subFormDataPoint: {} as DataPoint,
        showSubForm: false
      });
    }
  }

  @bind
  private setAnswer() {
    const { updateAnswer, question } = this.props;
    const { value } = this.state;
    const answer = {};
    answer[question.id] = value;
    updateAnswer(answer);
    this.updateColorCode();
  }

  @bind
  private showSubForm(index: number) {
    const { value, filtered, subForm, sortable } = this.state;
    const { question, forms, formUtils } = this.props;
    let subFormDataPoint;
    if (index === -1 ) {
      subFormDataPoint = { created: Date.now() };
      subFormDataPoint = initDataPoint(
        subFormDataPoint, subForm ? subForm : {}, undefined, undefined, question, forms
      );
      subFormDataPoint.id = `DV-${uuid()}`;
      if (sortable) {
        subFormDataPoint.positionQuestion = value.length;
      }
      if (subForm) {
        subFormDataPoint['subfrmfld'] = `${formUtils.getModel().ref}_${question.id}`;
        if (question.type === 'TaskQuestion') {
          subFormDataPoint['parentId'] = `${formUtils.getModel().ref}`;
          subFormDataPoint['parentRowId'] = '';
        }
      }
    } else {
      const subData = !question.static ? filtered[index] : value[index];
      subFormDataPoint = initDataPoint(subData, subForm ? subForm : {}, undefined, undefined, question, forms);
    }
    this.setState({ subFormDataPoint, currentIndex: index, showSubForm: true });
  }

  @bind
  private cancel() {
    const { saving } = this.state;
    this.setState({
      subFormDataPoint: {} as DataPoint,
      currentIndex: -1,
      showSubForm: false,
      confirmCancel: false,
      recording: false,
      saving: false
    }, () => {
      if (saving) {
        this.saveSubFormInstance();
      }
    });
  }

  @bind
  private getSubFormModal() {
    const { forms, dataPoint, parentModel, question, clientPersist, edit } = this.props;
    const { subForm, currentIndex, subFormDataPoint } = this.state;

    return (
      <GenericModal
        visible={this.state.showSubForm}
        cancel={edit ? () => this.setState({ confirmCancel: true }) : undefined}
        onConfirm={this.saveSubFormInstance}
        cancelText={edit ? getLocalization('cancel') : undefined}
        confirmText={getLocalization('return')}
        // title={formName}
        dialogClassName={'large-modal'}
        body={(
          <FormComponent
            model={subForm !== undefined ? subForm : {}}
            forms={forms}
            dataPoint={subFormDataPoint}
            updateAnswer={this.updateSubFormAnswer}
            parentDataPoint={dataPoint}
            parentModel={parentModel}
            parentQuestion={question}
            newAnswer={currentIndex === -1}
            clientPersist={clientPersist}
            readOnly={!edit}
          />
        )}
      />
    );
  }

  @bind
  private removeSubFormInstance() {
    const { value, filtered,  currentIndex, sortable,  } = this.state;
    const { question, clientPersist, forms } = this.props;
    let newValue = [...value];
    const newFiltered = [...filtered];
    if (question.static) {
      newValue.splice(currentIndex, 1);
    } else {
      const subform = newFiltered.splice(currentIndex, 1);
      newValue = newValue.filter(v => v.id !== subform[0].id);
    }
    const invalidObj = newValue.length > 0 ? validateSubForms(question, newValue, forms, clientPersist) : undefined;
    const invalid = invalidObj !== undefined;
    this.setState({
      invalid,
      value: this.sortSubforms(newValue, sortable),
      currentIndex: -1,
      filtered: this.sortSubforms(newFiltered, sortable)
    }, this.setAnswer);
    this.cancelModal();
  }

  @bind
  private confirmSubformRemove(index: number) {
    this.setState({ currentIndex: index, confirmRemove: true });
  }

  @bind
  private cancelModal() {
    this.setState({ confirmRemove: false, confirmCancel: false, saving: false });
  }

  @bind
  private sortSubforms(value, sortable: boolean) {
    const key = sortable ? 'positionQuestion' : 'Name';
    return value.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  }

  @bind
  private onDragEnd(fromIndex, toIndex) {
    const value = [...this.state.value];
    const item = value.splice(fromIndex, 1)[0];
    value.splice(toIndex, 0, item);
    value.forEach((v, i) => {
      v['positionQuestion'] = i;
    });
    this.setState({ value, filtered: value }, this.setAnswer);
  }

  @bind
  private updateColorCode() {
    const { subForm, value, localSPSSToId, obj, colorCode } = this.state;
    if (subForm?.colorCodeScript && value.length > 0 && localSPSSToId) {
      const formUtils = getFormUtils(subForm);
      const codes = {...colorCode};
      for (const v of value) {
        const response = updateScriptObject(localSPSSToId, obj, formUtils, v, [], [], []);
        const { updated, vars } = response;
        if (updated) {
          let script = subForm.colorCodeScript;
          const calculate = () => {
            try {
              script = script.trim().startsWith('return') || script.trim().indexOf('return')
                ? script.replace('return ', '') : script;
              return eval(vars.join(' ') + script);
            } catch (e) {
              return null;
            }
          };
          const result = calculate();
          if (result) {
            codes[v.id] = result;
          }
        }
      }
      this.setState({ colorCode: codes });
    } else {
      this.setState({ colorCode: {} });
    }
  }

  @bind
  private getButton(index, label) {
    const { question, edit, formUtils } = this.props;
    const { value, colorCode } = this.state;
    const code = colorCode && question.static && value.length === 1 ? colorCode[value[0].id] : null;
    if ((!question.static && edit) || question.static) {
      return (
        <button
          type="button"
          className={
            `btn btn-sm btn-primary part-of-btn
            ${question.static && (value.length === 0) ? 'btn-empty-static' : '' }`
          }
          style={code ? { backgroundColor: code, color: getTextColor(code), fontWeight : 'bold' } : undefined}
          onClick={() => this.showSubForm(index)}
          disabled={!edit ? question.static && value.length === 0 ? true : false : false}
        >
          <span dangerouslySetInnerHTML={{__html: label}} />
          {formUtils.getModel().showOriginalLanguage && question.static ? (
            <>
              <br />
              <OriginalFormText formId={formUtils.getModel().ref} viewId={question.id} />
            </>
          ) : null}
        </button>
      );
    }
    return null;
  }

  public componentDidMount(): void {
    this.updateColorCode();
    const { dataPoint } = this.props;
    if (dataPoint['galleryId']) {
      for (const v of this.state.value) {
        if (v['files']) {
          const found = v['files'].findIndex(f => f.id === dataPoint['galleryId']);
          if (found > -1) {
            this.showSubForm(found);
            this.props.updateAnswer({ galleryId: null});
          }
        }
      }
    }
  }

  @bind
  private filterSubforms() {
    const { value, search } = this.state;
    if (search) {
      const filtered = value.filter(v => {
        const keys = Object.keys(v);
        for (const key of keys) {
          if (
            key !== 'questionnaire_id' && key !== 'id' && `${v[key]}`.toLowerCase().search(search.toLowerCase()) > -1
          ) {
            return true;
          }
        }
        return false;
      });
      console.log(`found ${filtered.length}`);
      this.setState({ filtered });
    } else {
      this.setState({ filtered: value });
    }
  }

  @bind
  private getSearchBox() {
    const { question, formUtils } = this.props;
    const { search } = this.state;
    const responsiveClass = formUtils.getModel().responsiveLayout ? '' : 'col-3';
    return !question.static ? (
      <InputGroup className={`${responsiveClass} mb-2`}>
        <TextInputComponent
          onChange={(v) => this.setState({ search: `${v}`}, this.filterSubforms)}
          name={'search-subform'}
          value={search}
          placeholder={getLocalization('search')}
          extraClass={'form-control-sm'}
        />
        <InputGroup.Append>
          <Button size="sm" onClick={this.filterSubforms}>
            <i className="fa fa-search"/>
          </Button>
        </InputGroup.Append>
      </InputGroup>
    ) : null;
  }

  public render(): JSX.Element {
    const {
      filtered, value, formName, sortable, colorCode, search, shownInSelectionList, recording, confirmCancel
    } = this.state;
    const { question, formUtils, edit } = this.props;
    const required = question.optional ? null : (<span className="text-danger">*</span>);
    const instances = !question.static && filtered.length > 0 ? (
      <ul className="saved-part-of-forms">
        { filtered.map((v, i) => {
          const textQn = shownInSelectionList.map(id => v[id] || '').filter(v => v);
          const textQnShown = textQn.length > 0 ? ` : ${textQn.join(', ')}` : '';
          return (
            <li
              key={v.id}
              style={colorCode && colorCode[`${v.id}`] ? {
                borderLeft: '10px solid', borderLeftColor: colorCode[`${v.id}`]
              } : {}}
            >
              <a onClick={() => this.showSubForm(i)}>
                {v.Name && v.Name !== '' ? `${v.Name} ${textQnShown}` : `${getLocalization('noName')} ${textQnShown}`}
              </a>
              {edit && (
                <span
                  className="text-danger remove-subform"
                  onClick={() => this.confirmSubformRemove(i)}
                >
                  &times;
                </span>
              )}
            </li>
          );
        }) }
      </ul>
    ) : null;
    const label = !question.static ? (
      `${getLocalization('add')} ${formName}`
    ) : question.text.replace(/\n|\r/g, '<br />');
    const index = question.static && value.length === 1 ? 0 : -1;
    const clearBtn = question.static && value.length === 1 && edit ? (
      <button
        className="clear-subform-btn"
        onClick={() => this.confirmSubformRemove(0)}
        title={getLocalization('clearSubformTitle')}
      >
        <i className="fa fa-times-circle" aria-hidden="true" />
      </button>
    ) : null;

    const confirmRemove = this.state.confirmRemove ? (
      <ConfirmationModal
        onConfirm={this.removeSubFormInstance}
        onClose={this.cancelModal}
        localizations={{
          cancel: getLocalization('cancel'),
          confirm: getLocalization('yes'),
          confirmStyle: 'danger',
          header: (<h4>{getLocalization('confirm')}</h4>),
          body: (
            <p>
              { getLocalization(question.static ? 'confirmClearStaticSubform' : 'confirmSubformRemove')}
            </p>
          )
        }}
      />
    ) : null;
    const confirmCancelModal = () => confirmCancel ? (
      <ConfirmationModal
        onConfirm={this.cancel}
        onClose={this.cancelModal}
        localizations={{
          cancel: getLocalization('no'),
          confirm: getLocalization('yes'),
          confirmStyle: 'danger',
          header: (<h4>{getLocalization('confirm')}</h4>),
          body: (
            <p>
              {getLocalization(recording ? 'recordingAlert' : 'cancelEditingSubform')}
            </p>
          )
        }}
      />
    ) : null;

    return (
      <div className={`${'form-group'} ${formUtils.getResponsiveView(question)}`}>
        {this.state.showSubForm ? this.getSubFormModal() : null}
        {
          !question.static && (
            <>
              <p className="mb-0">
                {getQuestionText(question.text?.replace(/\n|\r/g, '<br />'))}
                {required}
              </p>
              {formUtils.getModel().showOriginalLanguage ? (
                <OriginalFormText formId={formUtils.getModel().ref} viewId={question.id} />
              ) : null}
              {this.getSearchBox()}
            </>
          )
        }
        {instances && (
          <>
            <div className="saved-part-of-forms">
              {sortable && search === '' ? (
                <ReactDragListView
                  onDragEnd={this.onDragEnd}
                  nodeSelector={'li'}
                  handleSelector={'li'}
                >
                  {instances}
                </ReactDragListView>
              ) : instances}
            </div>
          </>
        )}
        {this.getButton(index, label)}
        {clearBtn}
        {confirmRemove}
        {confirmCancelModal()}
        {this.state.invalid && (<p><span className="required">Mandatory fields missing</span></p>)}
      </div>
    );
  }
}
