import { AnyAction, ThunkDispatch, createAsyncThunk } from "@reduxjs/toolkit";
import { StateInterface } from 'Interfaces/StateInterface';
import { getRequestConfig } from "api";
import { request } from "api/request";
import { doGetFileUrl, doRotateImage } from '../api/file';

export const getFileUrl = (fileId: string, callBack: (url: string) => void) => {
  return (dispatch, getState) => {
    const responsePromise = doGetFileUrl(fileId, dispatch, getState);
    void responsePromise
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        if (callBack) {
          callBack(data);
        }
      });
  };
};

export const rotateImage = (angle: string, fileId: number, callBack: (id: number) => void) => {
  return (dispatch, getState) => {
    const responsePromise = doRotateImage(angle, fileId, dispatch, getState);
    void responsePromise
      .then((response) => response.json())
      .then((data) => {
        if (callBack && data.status === 'OK') {
          callBack(fileId);
        }
      });
  };
};


export const getPresignedUrl = createAsyncThunk(
  'file/getPresignedUrl',
  async (args: { mimeType: string; thumbnail: boolean}, thunkApi) => {
    const state = thunkApi.getState() as StateInterface;
    const config = getRequestConfig();
    const data = await request(
      `/json/file/getPresignedUrl`,
      {...config, method: 'POST', body: JSON.stringify(args)},
      thunkApi.dispatch as ThunkDispatch<StateInterface, any, AnyAction>,
      () => state
    ).then(res => res.json()).catch(e => {
      console.log(e);
    });
    return data;
  }
);
