import './ModuleAreaContainer.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import { Alert, ProgressBar } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import { StateInterface } from '../../Interfaces/StateInterface';
import { ModuleSelectionInterface } from '../../Interfaces/ModuleSelectionInterface';
import { moduleSelectionSelector } from '../../reducers/moduleSelectionReducer';
import { MODULES } from '../../Interfaces/ModuleSelectionInterface';
import FeedContainer from '../Feed/FeedContainer';
import TableModuleArea from '../Table/TableModuleArea';
import MapContainer from '../Map/MapContainer';
import ChartViewContainer from '../Charts/ChartViewContainer';
import SharedDataView from '../Share/';
import SingleInstanceContainer from '../SingleInstance/Containers/SingleInstanceContainer';
import GalleryContainer from '../Gallery/GalleryContainer';
import { getLocalization } from '../../global/global';
import { Schedule } from '../Schedule/Schedule';
import PowerBIView from '../PowerBI/';
import ManageMaps from '../ManageMaps';
import MySchedule from '../MySchedule';
import Dashboard from '../Dashboard';

interface IStateProps {
  moduleSelection: ModuleSelectionInterface;
  formsLoaded: boolean;
  roles: string;
  formCount: number;
}

const className = 'ModuleAreaContainer';

export class ModuleAreaContainerClass extends React.Component<IStateProps & RouteComponentProps> {

  public render(): JSX.Element {
    // eslint-disable-next-line complexity
    const module = () => {
      const { formsLoaded, formCount, roles, moduleSelection} = this.props;
      if (!formsLoaded && moduleSelection.selected !== MODULES.DASHBOARD) {
        return (
          <div>
            <ProgressBar
              striped
              now={100}
              animated
            />
            <p>{getLocalization('loadingForms')}</p>
          </div>
        );
      }
      if (formsLoaded && formCount === 0) {
        return (
          <Alert variant="warning">
            {(roles.indexOf('admin') !== -1 || roles.indexOf('modeler') !== -1 ) ?
              getLocalization('noFormList') : getLocalization('noFormListEnum')}
          </Alert>
        );
      }
      if (moduleSelection.singleInstance) {
        return (
          <SingleInstanceContainer {...moduleSelection.singleInstance}/>
        );
      }
      switch (moduleSelection.selected) {
        case MODULES.FEED:
          return (<FeedContainer />);
        case MODULES.TABLE:
          return (<TableModuleArea tasks={false} />);
        case MODULES.SCHEDULE:
          return (<Schedule />);
        case MODULES.TASKS:
          return (<TableModuleArea tasks />);
        case MODULES.MAP_AND_TABLE:
          return (
            <React.Fragment>
              <MapContainer id={`global-map`} />
              <TableModuleArea tasks={false} />
            </React.Fragment>
          );
        case MODULES.GALLERY:
          return (<GalleryContainer />);
        case MODULES.MAP:
          return (<MapContainer id={`global-map`} />);
        case MODULES.CHARTS:
          return (<ChartViewContainer />);
        case MODULES.SHARED_DATA:
          return (<SharedDataView />);
        case MODULES.DATA_URLS:
          return (<PowerBIView />);
        case MODULES.MANAGE_MAPS:
          return (<ManageMaps />);
        case MODULES.MY_SCHEDULE:
          return (<MySchedule />);
        case MODULES.DASHBOARD:
          return (<Dashboard />);
        default:
          return (
            <div className={className}>
              Modules selected will be rendered here.
              Currently rendered Module is
              <h2>{this.props.moduleSelection.selected}</h2>
            </div>
          );
      }
    };
    return (
      <div className={className}>
        {module()}
      </div>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  return {
    moduleSelection: moduleSelectionSelector(state),
    formsLoaded: state.forms.loaded,
    formCount: state.forms.collection.length,
    roles: state.clientPersist.roles
  };
};

export const ModuleAreaContainer = withRouter(
  connect<IStateProps, undefined, RouteComponentProps, StateInterface>(
    mapStateToProps
  )(ModuleAreaContainerClass)
);
