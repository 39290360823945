import { transcribeFile } from 'actions/transcribingActions';
import { getLocalization } from 'global/global';
import { useAppDispatch, useAppSelector } from 'index';
import { FileInterface } from 'Interfaces/DataPoint';
import * as React from 'react';
import { Button } from 'react-bootstrap';

interface Props {
  file: FileInterface;
  updateDataPoint: (text: string, fileId: string) => void;
}

export default function TranscribeButton(props: Props) {
  const dispatch = useAppDispatch();
  const clientPersist = useAppSelector(state => state.clientPersist);
  const [loading, setLoading] = React.useState(false);

  const transcribeFileAction = async () => {
    const { file } = props;
    setLoading(true);
    const res = await dispatch(transcribeFile(file.file, file.id, file.mimeType));
    if (!res || res.errorCode) {
      console.log(res);
    } else {
      props.updateDataPoint(res['text'], res['fileId']);
    }
    setLoading(false);
    console.log(res);
  };

  return (
    <Button
      size="sm"
      onClick={() => void transcribeFileAction()}
      disabled={loading || clientPersist.package !== 'Custom'}
    >
      {getLocalization(loading ? 'loading' : 'transcribe')}
    </Button>
  );
}
