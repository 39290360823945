import axios from 'axios';
import { toast } from 'react-toastify';
import { ThunkDispatchAction } from 'actions';
import { StateInterface } from 'Interfaces/StateInterface';
import { LocationHierarchyInterface } from 'Interfaces/HierarchyInterface';
import { getLocalization } from '../global/global';
import { LocationInterface, undefinedLocationKey } from '../Interfaces/LocationInterface';
import { ClientPersistInterface } from '../Interfaces/ClientPersistInterface';
import { request } from './request';
import { getRequestConfig } from './index';

export const getLocations = async (userId: ClientPersistInterface['user_id']): Promise<LocationInterface[]> => {
  try {
    const url = `/json/app/location/list/${userId}`;
    const response = await axios.get(url, getRequestConfig());
    const data: LocationInterface[] = response.data;
    return [
      ...data,
      {
        title: getLocalization('undefinedLocation'),
        key: undefinedLocationKey,
        parent: '-1',
        level: '0',
      },
    ];
  } catch (err: any) {
    const { data } = err.response;
    if (data.errorCode && data.errorCode === '1009') {
      toast.error('session expired.');
      return [];
    }
  }
  toast.error('An error occurred while fetching locations list. Please Contact Support.');
  return [];
};

export const addNewLocation = (
  id: number,
  locations: Pick<LocationInterface, 'title' | 'level' | 'parent' | 'identifier'>[],
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  return request(
    `/json/lh/${id}`,
    Object.assign({}, headers, { method: 'POST', body: JSON.stringify(locations) }),
    dispatch,
    getState,
  );
};

export const getLocationHierarchies = async (): Promise<LocationHierarchyInterface[]> => {
  try {
    const url = '/json/atool/app/lh/list';
    const response = await axios.post(url, null, getRequestConfig());
    const data: LocationHierarchyInterface[] = response.data.locationhierarchies;
    return [...data];
  } catch (err) {
    toast.error('An error occurred while fetching locations list. Please Contact Support.');
    throw err;
  }
};

export const doSaveHierarchies = (hierarchies, dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
  const headers = getRequestConfig();
  headers['method'] = 'POST';
  headers['body'] = JSON.stringify(hierarchies);
  return request('/json/atool/app/lh/save', headers, dispatch, getState);
};

export const doArchiveHierarchy = (
  id: number,
  action: 'true' | 'false',
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  headers['method'] = 'POST';
  return request(`/json/atool/app/lh/archiveHierarchy/${id}/${action}`, headers, dispatch, getState);
};

export const doUpdateGroupHierarchy = (
  id: number,
  groupId: number,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  headers['method'] = 'POST';
  return request(`/json/atool/app/updateLHGroup/${id}/${groupId}`, headers, dispatch, getState);
};
