import { TranscribingInterface } from "Interfaces/TranscribingInterface";
import { getRequestConfig } from "api";
import { StateInterface } from "Interfaces/StateInterface";
import { request } from "api/request";
import { TRANSCRIBING } from "./actionTypes";

export const startTranscribing = (transcribing: TranscribingInterface) => ({ type: TRANSCRIBING.START, transcribing });

export const doneTranscribing = () => ({ type: TRANSCRIBING.DONE });

export const transcribeFile = (file: File, fileId?: string, mimeType?: string) => {

  return async (dispatch, getState: () => StateInterface) => {
    const { clientPersist } = getState();
    const headers = getRequestConfig();
    const data = { ...headers };
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    formData.append('lang', clientPersist.transcriptionLang || '');
    formData.append('fileId', fileId || '');
    formData.append('mimeType', mimeType || '');
    delete data.headers['Content-Type']; // = 'multipart/form-data';

    data['method'] = 'PUT';
    data['body'] = formData;
    try {
      const response = await request(`/json/app/answer/transcribe`, data, dispatch, getState);
      const json = await response.json();
      return json;
    } catch (e) {
      return null;
    }
  };
};
