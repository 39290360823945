import * as React from 'react';
import bind from 'bind-decorator';
import { Button, Form } from 'react-bootstrap';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { MODULES } from '../../Interfaces/ModuleSelectionInterface';
import GenericModal from '../Modals/GenericModal';
import { getLocalization } from '../../global/global';
import { ChartModel, CHART_TYPES } from '../../Interfaces/ChartInterface';
import { getFormUtils } from '../../views/SingleInstance/utils/FormUtilsHolder';

interface Props {
  forms: FormInterface[];
  addNewChart: (chart: ChartModel) => void;
  selectChart: (chartId: string) => void;
  onModuleSelectClick: () => void;
  selectedModule: string;
}

interface State {
  showModal: boolean;
  form: string;
  chartType: CHART_TYPES;
  layoutTables: LayoutTableOption[];
}

interface LayoutTableOption {
  id: string;
  name: string;
}

export default class NewChartButton extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      form: '',
      chartType: 'BAR_NUMERIC',
      layoutTables: []
    };
  }

  @bind
  private getFormType(form) {
    switch (form.type) {
      case 'TABLE':
        return 'Table : ';
      case 'TASKFORM':
        return 'Taskform : ';
      case 'POI':
        if (form.isChild) {
          return 'Subform : ';
        }
        return '';
      default:
        return '';
    }
  }

  @bind
  private getModalBody() {
    return (
      <div>
        {/* <Form.Group>
          <Form.Label>{getLocalization('filterFormSelect')}</Form.Label>
          <Form.Control
            as="select"
            name="form"
            value={this.state.form}
            onChange={this.handleSelectChange}
            size="sm"
          >
            <option value="">{getLocalization('selectOne')}</option>
            {this.props.forms.map((form) => {
              return (
                <option value={form.ref} key={`new-chart-form-option-${form.ref}`}>
                  {`${this.getFormType(form)} ${form.name} `}
                </option>
              );
            })}
            {this.state.layoutTables.map((layoutTable) => {
              return (
                <option value={layoutTable.id} key={`new-chart-form-option-${layoutTable.id}`}>
                  {`${layoutTable.name}`}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>*/}
        <Form.Group>
          <Form.Label>{getLocalization('reporttype')}</Form.Label>
          <Form.Control
            as="select"
            name="chartType"
            size="sm"
            value={this.state.chartType}
            onChange={this.handleSelectChange}
          >
            <option value="BAR_NUMERIC">{getLocalization('barchartsNumeric')}</option>
            <option value="BAR_CHOICE">{getLocalization('barchartsChoice')}</option>
            <option value="LINE_NUMERIC">{getLocalization('linechartNumeric')}</option>
            <option value="LINE_CHOICE">{getLocalization('linechartChoice')}</option>
            <option value="PIE">{getLocalization('piechart')}</option>
            <option value="RADAR">{getLocalization('radarChart')}</option>
          </Form.Control>
        </Form.Group>
      </div>
    ); // <option value="1">Area Chart</option>
  }

  @bind
  private handleSelectChange(e) {
    const state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  @bind
  private setShowModal(showModal) {
    this.setState({ showModal });
  }

  @bind
  private getReportName(formName, reportType) {
    switch (reportType) {
      case 'BAR_NUMERIC':
      case 'BAR_CHOICE':
        return `Bar chart ${formName}`;
      case '1':
        return `Area chart ${formName}`;
      case 'LINE_NUMERIC':
      case 'LINE_CHOICE':
        return `Line chart ${formName}`;
      case 'PIE':
        return `Pie chart ${formName}`;
      case 'RADAR':
        return `Radar chart ${formName}`;
      default:
        return '';
    }
  }

  @bind
  private createChart() {
    this.setShowModal(false);
    const { chartType, form } = this.state;
    const id = `new-${Date.now()}`;
    let formName;
    if (form.indexOf('-') !== -1) {
      const split = form.split('-');
      const formJSON = this.props.forms.find(f => f.ref === split[0]);
      const formUtil = getFormUtils(formJSON);
      const question = formUtil.getQuestion(split[1]);
      formName = question.text;
    } else {
      const formJSON = this.props.forms.find(f => f.ref === form);
      formName = formJSON ? formJSON.name : '';
    }

    const chart: ChartModel = {
      type: chartType,
      id: id,
      name: this.getReportName(formName, chartType),
      questions: [],
      average: false,
      scale: 'DAY',
      forms: {},
      dataPoints: [],
      dataFilter: 'OWN'
    };
    this.props.addNewChart(chart);
  }

  @bind
  private onClick() {
    this.setShowModal(true);
    if (this.props.selectedModule !== `${MODULES.CHARTS}`) {
      this.props.onModuleSelectClick();
    }
  }

  /* public componentDidUpdate(prevProps: Props) {
    if (this.props.forms.length > prevProps.forms.length) {
      const layoutTables: LayoutTableOption[] = [];
      this.props.forms.forEach(form => {
        const formUtil = getFormUtils(form);
        const lt = formUtil.getLayoutTables();
        lt.forEach(layoutTable => {
          const qn = formUtil.getQuestion(layoutTable);
          if (qn) {
            layoutTables.push({
              id: `${form.ref}-${qn.id}`,
              name: `Layout table: ${form.name} - ${qn.text}`
            });
          }
        });
      });
      this.setState({ layoutTables });
    }
  }*/

  public render() {
    const modal = !this.state.showModal ? null : (
      <GenericModal
        title={getLocalization('createanewchart')}
        visible={this.state.showModal}
        body={this.getModalBody()}
        onConfirm={this.createChart}
        cancel={() => this.setShowModal(false)}
        confirmText={getLocalization('defineReport')}
        cancelText={getLocalization('cancel')}
      />
    );

    return (
      <React.Fragment>
        {modal}
        <Button variant="primary" size="sm" onClick={this.onClick}>
          <i className="fa fa-plus"/>
        </Button>
      </React.Fragment>
    );
  }
}
