import { getLocalization } from 'global/global';
import { useAppSelector } from 'index';
import { ChartModel } from 'Interfaces/ChartInterface';
import { questionTypes } from 'Interfaces/Forms/QuestionInterface';
import * as React from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { getFormUtils } from 'views/SingleInstance/utils/FormUtilsHolder';

interface Props {
  model: ChartModel;
  updateModelQuestions: (checked: boolean, questionId: string, formId: string) => void;
}

interface PartialQuestion {
  text: string;
  id: string;
}

interface Questions {
  [key: string]: { name: string; questions: PartialQuestion[]};
}

export default function ChartOptionQuestions(props: Props) {
  const forms = useAppSelector(state => state.forms.collection);
  const [questions, setQuestions] = React.useState<Questions>({});
  const [showDropdown, setShowDropdown] = React.useState(false);

  const initQuestions = (questionList) => {
    const { model } = props;
    const list: PartialQuestion[] = [];

    const addNumericQuestions = (question) => {
      if (question.id.toLowerCase() === 'countquestion' || (
        (question.type !== questionTypes.CALCULATED_VALUE_QUESTION || question.numericOnly) && !question.inVisible)
      ) {
        // add the question if this is not a pie chart
        if (`${model.type}` !== 'PIE' && `${model.type}` !== 'BAR_CHOICE' && `${model.type}` !== 'LINE_CHOICE') {
          list.push({ text: question.text, id: question.id });
        }
      }
    };

    const addChartStringQuestion = (question) => {
      if (question.validationlist && question.validationlist.length > 0) {
        if (model.type === 'BAR_CHOICE') {
          list.push({ text: question.text, id: question.id });
        }
      }
    };

    const addSelectQuestions = (question) => {
      if (model.type !== 'BAR_NUMERIC' && model.type !== 'LINE_NUMERIC' && model.type !== 'RADAR') {
        list.push({ text: question.text, id: question.id });
      }
    };
    if (model.type === 'LINE_CHOICE') {
      list.push({
        text: getLocalization('createdBy'), id: 'createdBy'
      });
      list.push({
        text: getLocalization('editor'), id: 'user_id'
      });
    }
    const keys = Object.keys(questionList);
    for (const key of keys) {
      const question = questionList[key];
      if (question.inVisible && question.id.toLowerCase() !== 'countquestion') {
        continue;
      }
      if (question.id === 'countQuestion' && !question.text) {
        question.text = 'Count';
      }
      switch (question.type) {
        case 'IntQuestion':
        case 'FloatQuestion':
        case 'CalculatedValueQuestion':
          addNumericQuestions(question);
          break;
        case 'SelectOneQuestion':
        case 'SelectMultipleQuestion':
        case 'BooleanQuestion':
        case 'LikertScaleQuestion':
        case 'StatusQuestion':
          // these questions are available in all chart types.
          addSelectQuestions(question);
          break;
        case 'StringQuestion':
          addChartStringQuestion(question);
          break;
        default:
          break;
      }
    }
    return list;
  };

  React.useEffect(() => {
    const { model } = props;
    const chartForms = model.forms;
    const formIds = Object.keys(chartForms);
    const singleForm = model.type === 'RADAR' || model.type === 'PIE';
    const addedQuestions = Object.keys(questions);
    // A new form has been selected
    if (formIds.length > addedQuestions.length || (singleForm && formIds !== addedQuestions)) {
      const newFormIds = singleForm ? formIds : formIds.filter(formId => addedQuestions.indexOf(formId) === -1);
      const newQuestions = singleForm ? {} : {...questions};
      for (const formId of newFormIds) {
        if (formId.indexOf('-') > -1) {
          const chartForm = getLayoutTableColumns(formId);
          if (chartForm) {
            newQuestions[formId] = chartForm;
          }
        } else {
          const form = forms.find(f => f.ref === formId);
          if (form) {
            const formUtils = getFormUtils(form);
            const qns = formUtils.getQuestions();
            newQuestions[formId] = {
              name: form.name,
              questions: initQuestions(qns)
            };
          }
        }
      }
      setQuestions(newQuestions);
    } else if (formIds.length < addedQuestions.length) { // A form has been unselected
      const removed = addedQuestions.filter(formId => formIds.indexOf(formId) === -1);
      const newQuestions = {...questions};
      for (const id of removed) {
        delete newQuestions[id];
      }
      setQuestions(newQuestions);
    }
  }, [props.model.forms]);

  const getLayoutTableColumns = (formId: string) => {
    const id = formId.split('-');
    const form = forms.find(f => f.ref === id[0]);
    if (form) {
      const formUtil = getFormUtils(form);
      const question = formUtil.getQuestion(id[1]);
      if (question) {
        const table = question.table;
        if (table) {
          const columns = table.columns;
          if (columns && columns.column) {
            const newCols = [...columns.column];
            newCols.splice(0, 1);
            return {
              name: `Layout table: ${form.name} - ${question.text}`,
              questions : newCols.map(c => ({ id: c.id, text: c.name}))
            };
          }
        }
      }
    }
    return null;
  };

  const onToggle = (isOpen, event, metadata) => {
    if (metadata.source === 'select') {
      setShowDropdown(true);
    } else {
      setShowDropdown(isOpen);
    }
  };

  const getQuestionList = React.useCallback(() => {
    const keys = Object.keys(questions);
    return keys.map((key: string) => {
      const qns = questions[key].questions.map(q => {
        return q.text && q.text !== '' ? (
          <Dropdown.Item as="div" key={`${q.id}-${key}-chart-qn`}>
            <Form.Check
              id={`chart-question-down-checkbox-${q.id}`}
              type={'checkbox'}
              label={q.text}
              onChange={(e) => props.updateModelQuestions(e.target.checked, e.target.value, key)}
              value={q.id}
              checked={props.model.forms[key]?.questions.indexOf(q.id) !== -1}
            />
          </Dropdown.Item>
        ) : null;
      }).filter(q => q !== null);
      return [<Dropdown.Header key={`header-${key}`}>{questions[key].name}</Dropdown.Header>].concat(qns);
    });
  }, [questions, props.model.forms]);

  return (
    <div className="btn-group col">
      <Dropdown className="chart-content-spacing" onToggle={onToggle} show={showDropdown}>
        <Dropdown.Toggle variant="primary" size="sm" id="chart-select-questions">
          <i className="fa fa-list-ol" />
          {getLocalization('selectQuestions')}
        </Dropdown.Toggle>
        <Dropdown.Menu className="chart-questions-drop-down">
          {getQuestionList()}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
