import { getLocalization } from 'global/global';
import { useAppSelector } from 'index';
import { ChartModel } from 'Interfaces/ChartInterface';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import * as React from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { uniqueFormsSelector } from 'reducers/formsReducer';
import { isNullOrUndefined } from 'utils/utils';
import { getFormUtils } from 'views/SingleInstance/utils/FormUtilsHolder';

interface Props {
  updateModel: (model: ChartModel) => void;
  model: ChartModel;
}


interface LayoutTableOption {
  id: string;
  name: string;
}

export default function ChartOptionForms(props: Props) {
  const forms = useAppSelector(state => uniqueFormsSelector(state));

  const [showDropdown, setShowDropdown] = React.useState(false);

  const layoutTables = React.useMemo(() => {
    const layoutTables: LayoutTableOption[] = [];
    forms.forEach(form => {
      const formUtil = getFormUtils(form);
      const lt = formUtil.getLayoutTables();
      lt.forEach(layoutTable => {
        const qn = formUtil.getQuestion(layoutTable);
        if (qn) {
          layoutTables.push({
            id: `${form.ref}-${qn.id}`,
            name: `Layout table: ${form.name} - ${qn.text}`
          });
        }
      });
    });
    return layoutTables;
  }, [forms]);

  const getFormType = (form: FormInterface) => {
    switch (form.type) {
      case 'TABLE':
        return 'Table : ';
      case 'TASKFORM':
        return 'Taskform : ';
      case 'POI':
        if (form.isChild) {
          return 'Subform : ';
        }
        return '';
      default:
        return '';
    }
  };

  const onToggle = (isOpen, event, metadata) => {
    if (metadata.source === 'select') {
      setShowDropdown(true);
    } else {
      setShowDropdown(isOpen);
    }
  };

  const onFormSelect = (e) => {
    const { checked, value } = e.target;
    const { model } = props;
    const singleForm = model.type === 'RADAR' || model.type === 'PIE';
    if (checked) {
      props.updateModel({
        ...model,
        xaxis: '',
        stack: '',
        forms: {
          ...(!singleForm ? {...model.forms} : {}),
          [value]: { formId: value, questions: []}
        }
      });
    } else {
      const newModel = {...model, forms: {...model.forms}};
      delete newModel.forms[value];
      props.updateModel(newModel);
    }
  };

  return (
    <div className="btn-group col">
      <Dropdown className="chart-content-spacing" onToggle={onToggle} show={showDropdown}>
        <Dropdown.Toggle variant="primary" size="sm" id="chart-select-forms">
          <i className="fa fa-list-ol" />
          {getLocalization('filterFormSelect')}
        </Dropdown.Toggle>
        <Dropdown.Menu className="chart-form-drop-down">
          {forms.map((form) => {
            return (
              <Dropdown.Item as="div" key={`new-chart-form-option-${form.ref}`}>
                <Form.Check
                  id={`chart-form-down-checkbox-${form.ref}`}
                  type={'checkbox'}
                  label={`${getFormType(form)} ${form.name} `}
                  onChange={onFormSelect}
                  value={form.ref}
                  checked={!isNullOrUndefined(props.model.forms[form.ref])}
                />
              </Dropdown.Item>
            );
          })}
          {layoutTables.map((layoutTable) => {
            return (
              <Dropdown.Item as="div" key={`new-chart-form-option-${layoutTable.id}`}>
                <Form.Check
                  id={`chart-form-down-checkbox-${layoutTable.id}`}
                  type={'checkbox'}
                  label={`${layoutTable.name} `}
                  onChange={onFormSelect}
                  value={layoutTable.id}
                  checked={!isNullOrUndefined(props.model.forms[layoutTable.id])}
                />
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
