import { getLocalization, globalWindow } from 'global/global';
import { useAppSelector } from 'index';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import GenericModal from 'views/Modals/GenericModal';

interface Props {
  model: FormInterface;
}

export default function Reportutton(props: Props) {
  const { model } = props;
  const filtersMenu = useAppSelector((state: StateInterface) => state.filtersMenu);

  const [show, setShow] = React.useState(false);
  const [exportType, setExportType] = React.useState('');
  const [validate, setValidate] = React.useState(false);
  const [exportAsPdf, setExportAsPdf] = React.useState(false);
  const [includeImages, setIncludeImages] = React.useState(false);
  const [imageSize, setImageSize] = React.useState('');
  const [hasWordAndExcel] = React.useState(model.hasWordTemplate && model.hasExcelTemplate);

  React.useEffect(() => {
    if (hasWordAndExcel) {
      setExportType('');
    } else {
      if (model.hasWordTemplate) {
        setExportType('word_template');
      } else if (model.hasExcelTemplate) {
        setExportType('excel_template');
      }
    }
  }, [hasWordAndExcel, model]);

  const exportData = () => {
    if (!show) {
      setShow(true);
    } else {
      if (hasWordAndExcel && exportType === '') {
        setValidate(true);
        return;
      }
      const params: string[] = [
        `key=${globalWindow.pwrd}`, `userid=${globalWindow.userName}`
      ];
      params.push(`locs=${filtersMenu.selectedLocations.map(l => l.key).join(',')}`);
      params.push(`levels=${filtersMenu.selectedLocations.map(l => l.level ? Number(l.level) + 1 : 0).join(',')}`);
      params.push(`users=${filtersMenu.selectedUsers.map(u => u.id).join(',')}`);
      params.push(`from=${filtersMenu.selectedDates.from}`);
      params.push(`to=${filtersMenu.selectedDates.to}`);
      params.push(`dateFilterBy=${filtersMenu.selectedDates.filterBy}`);
      params.push(`images=${includeImages ? 1 : 0}`);
      params.push(`pdf=${exportAsPdf ? 1 : 0}`);
      params.push(`imageSize=${imageSize}`);
      params.push(`type=${hasWordAndExcel ? exportType : model.hasWordTemplate ? 'word_template' : 'excel_template'}`);
      setValidate(false);
      setShow(false);

      const url = `/json/app/export/templates-export/${model.ref}?${params.join('&')}`;
      globalWindow.open(url, '_blank');
    }
  };

  const getBody = () => {
    return (
      <>
        {hasWordAndExcel && (
          <Form.Group>
            <Form.Control
              as='select'
              value={exportType}
              onChange={(e) => setExportType(e.target.value)}
              size='sm'
              isInvalid={validate && exportType === ''}
            >
              <option value={''}>{getLocalization('selectOne')}</option>
              <option value={'word_template'}>{getLocalization('wordExport')}</option>
              <option value={'excel_template'}>{getLocalization('excelExport')}</option>
            </Form.Control>
          </Form.Group>
        )}
        <Form.Check
          id={`report-export-as-pdf`}
          checked={exportAsPdf}
          label={getLocalization('exportAsPdf')}
          onChange={(e) => setExportAsPdf(e.target.checked)}
        />
        <Form.Check
          id={`report-include-images`}
          checked={includeImages}
          label={getLocalization('includeimages')}
          onChange={(e) => setIncludeImages(e.target.checked)}
        />
        {exportType === 'word_template' && includeImages && (
          <>
            <Form.Check
              id={`report-reduced-medium-images`}
              checked={imageSize === 'medium'}
              label={getLocalization('mediumImages')}
              onChange={() => setImageSize('medium')}
              className='ml-4'
            />
            <Form.Check
              id={`report-reduced-smalle-images`}
              checked={imageSize === 'small'}
              label={getLocalization('smallImages')}
              onChange={() => setImageSize('small')}
              className='ml-4'
            />
          </>
        )}
      </>
    );
  };

  const modal = show ? (
    <GenericModal
      visible={show}
      title={getLocalization('exportType')}
      cancel={() => setShow(false)}
      cancelText={getLocalization('cancel')}
      confirmText={getLocalization('exportData')}
      onConfirm={exportData}
      body={getBody()}
    />
  ) : null;

  return (
    <>
      {modal}
      <Button size="sm" onClick={exportData}>
        {getLocalization('report')}
      </Button>
    </>
  );
}
