import moment from 'moment';
import { WindowInterface } from '../Interfaces/WindowInterface';
import { showAdminImport } from '../views/admin/ImportUsers';
import { showAccountSettings } from '../views/admin/AccountSettingsModal';
import { showChartModal } from '../views/Modals/ChartDataPointsModal';

export const globalWindow: WindowInterface = window as any;

export const getLocalization = (key: string): string => {
  const translation = globalWindow['messages'][key];
  return translation ? translation : `[${key}]`;
};
globalWindow.moment = moment;
globalWindow.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const globalPoimapper = globalWindow.poimapper;

globalWindow.setAuthorized = () => {
  if (window['ReactAppBridge']) {
    window['ReactAppBridge'].ReduxActions.updateClientPersist({ googleAccessToken: true });
  }
};

globalWindow.showAdminImport = showAdminImport;
globalWindow.showAccountSettings = showAccountSettings;
globalWindow.showChartModal = showChartModal;
