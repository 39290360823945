import { request } from 'api/request';
import { getRequestConfig } from 'api';
import { updatetableHiddenColumns } from 'reducers/tableHiddenColumns';
import { TABLE } from '../actions/actionTypes';

export const selectTable = (formId) => ({ type: TABLE.SELECT_TABLE, formId });

export const saveTableColumnsVisibility = (questionnaireId: string, columns: string[]) => {
  return async (dispatch, getState) =>  {
    const headers = getRequestConfig();
    headers['method'] = 'POST';
    headers['body'] = JSON.stringify(columns);
    const json = await request(`/json/user/column-visibility/${questionnaireId}`, headers, dispatch, getState)
      .then(res => res.json()).catch(e => {
        console.log(e);
      });
    if (json.status === 'OK') {
      dispatch(updatetableHiddenColumns({ questionnaireId, columns }));
    }
  };
};

export const getTableColumnsVisibility = (questionnaireId: string) => {
  return (dispatch, getState) =>  {
    const headers = getRequestConfig();
    headers['method'] = 'GET';
    const response = request(`/json/user/column-visibility/${questionnaireId}`, headers, dispatch, getState);
    response.then(res => res.json()).then(json => {
      if (Array.isArray(json) && json.length > 0) {
        dispatch(updatetableHiddenColumns({ questionnaireId, columns: json }));
      }
    }).catch(e => {
      console.log(e);
    });
  };
};
