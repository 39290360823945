
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HiddenColumns, TableHiddenColumnsInterface } from 'Interfaces/Table/TableHiddenColumnsInterface';

const initialState: TableHiddenColumnsInterface  = {};

const tableHiddenColumnsSlice = createSlice({
  name: 'tableHiddenColumns',
  initialState,
  reducers: {
    updatetableHiddenColumns(state, action: PayloadAction<HiddenColumns>) {
      return {
        ...state,
        [action.payload.questionnaireId]: action.payload
      };
    },
  },
});

export const {
  updatetableHiddenColumns,
} = tableHiddenColumnsSlice.actions;

export default tableHiddenColumnsSlice.reducer;
