import { ComponentClass, FunctionComponent, NamedExoticComponent } from 'react';
import { ModalComponentNames } from 'Interfaces/ModalInterface';
import AddChartToDashboardModal from '../Dashboard/AddChartToDashboardModal';
import AddDataPointToDashboardModal from '../Dashboard/AddDataPointToDashboardModal';
import ImportUsersModal from '../admin/ImportUsers';
import AccountSettingsModal from '../admin/AccountSettingsModal';
import { EmailNotificationModal } from './EmailNotificationModal';
import { ChangePasswordModal } from './ChangePassword';
import { UpgradeAccountModal } from './UpgradeAccountModal';
import { ShowVideoModal } from './ShowVideoModal';
import { ConfirmationModal } from './ConfirmationModal';
import { ScheduleDayInfoModal } from './ScheduleDayInfoModal';
import { ScheduleUserSelectionModal } from './ScheduleUserSelectionModal';
import { ScheduleConfirmCopyModal } from './ScheduleConfirmCopyModal';
import { NewDashboardModal } from './NewDashboardModal';
import Setup2FAModal from './Setup2FAModal';
import ChartDataPointsModal from './ChartDataPointsModal';
import AppModal from './AppModal';

/**
 * NOTE: Also add the name in enum ModalComponentNames in ~./Interfaces/ModalInterface
 * @type {{EmailNotificationModal: EmailNotificationModal}}
 */
const ModalsList: Record<
ModalComponentNames,
NamedExoticComponent<any> | ComponentClass<any, any> | FunctionComponent<any>
> = {
  EmailNotificationModal: EmailNotificationModal,
  ChangePasswordModal,
  UpgradeAccountModal,
  ShowVideoModal,
  ConfirmationModal,
  ScheduleDayInfoModal,
  ScheduleUserSelectionModal,
  ScheduleConfirmCopyModal,
  NewDashboardModal,
  AddChartToDashboardModal,
  ImportUsersModal,
  Setup2FAModal,
  AccountSettingsModal,
  ChartDataPointsModal,
  AppModal,
  AddDataPointToDashboardModal
};

export default ModalsList;
