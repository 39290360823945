import './Style.scss';
import * as React from 'react';
import { ListGroup, Pagination } from 'react-bootstrap';
import { getLocalization } from 'global/global';
import { useAppDispatch, useAppSelector } from 'index';
import { DataPoint } from 'Interfaces/DataPoint';
import { uniqueFormsSelector } from 'reducers/formsReducer';
import { navigateRemoveModal } from 'actions/navigationAction';
import { setSingleInstance } from 'actions/moduleSelectionActions';
import { getFormUtils } from 'views/SingleInstance/utils/FormUtilsHolder';
import { ModalComponentNames, ModalInterface } from 'Interfaces/ModalInterface';
import GenericModal from '../GenericModal';

interface ChartDataPointsModalProps {
  chartId: string;
  xAxis: string;
  xAxisQuestionId: string;
  yAxis?: string;
  stack?: string;
  groupBy?: string;
  grouping?: string;
  formId?: string;
}

export default function ChartDataPointsModal(props: ChartDataPointsModalProps) {
  const PAGE_SIZE = 15;
  const dispatch = useAppDispatch();
  const chartDataPoints = useAppSelector(state => state.chartDataPoints);
  const forms = useAppSelector(state => uniqueFormsSelector(state));
  const [page, setPage] = React.useState(0);
  const close = () => dispatch(navigateRemoveModal(ModalComponentNames.ChartDataPointsModal));

  const setSI = (dp) => {
    const form = forms.find(f => f.ref === dp['questionnaire_id']);
    if (form) {
      let singleInstance = {
        model: form,
        dataPoint: {
          row_id: dp['_id'],
          questionnaire_id: form.ref,
          formId: form.id
        },
        newAnswer: false
      };
      if (form.isChild) {
        const subfrmfld = dp['subfrmfld'];
        if (subfrmfld) {
          const parts = subfrmfld.split('_');
          const parentForm = forms.find(f => f.ref === parts[0]);
          if (parentForm) {
            singleInstance = {
              model: parentForm,
              dataPoint: {
                row_id: dp['parentRowId'],
                questionnaire_id: parentForm.ref,
                formId: parentForm.id
              },
              newAnswer: false
            };
          }
        }
      }
      dispatch(setSingleInstance(singleInstance));
      close();
    }
  };

  const changePage = (change: number) => {
    const newPage = page + change;
    if (newPage <= 0 || dataPoints.length < newPage * PAGE_SIZE) {
      setPage(0);
    }
    setPage(newPage);
  };

  const dataPoints = React.useMemo(() => {
    const { xAxis, yAxis, stack, groupBy, grouping, formId } = props;
    const data = chartDataPoints[props.chartId];
    const chartData = formId ? data[formId] : data;
    if (yAxis) {
      const yAxisData = chartData[yAxis];
      let dataPoints: DataPoint[] = [];
      if (yAxisData[props.xAxis]) {
        const data = yAxisData[xAxis];
        if (stack && data[stack]) {
          dataPoints = data[stack];
        } else {
          dataPoints = yAxisData[props.xAxis];
        }
      } else if (Array.isArray(yAxisData)) {
        dataPoints = yAxisData;
      }
      if (groupBy && grouping) {
        return dataPoints.filter(dp => `${dp[groupBy]}` === `${grouping}`);
      }
      return dataPoints;
    } else {
      const d = chartData[props.xAxis];
      if (Array.isArray(d)) {
        return d;
      }
    }
    return [];
  }, [props.xAxis, props.chartId, props.yAxis]);

  const getXAxisQuestion = React.useMemo(() => {
    if (dataPoints.length > 0 && props.xAxisQuestionId) {
      const dp = dataPoints[0];
      const form = forms.find(f => f.ref === dp['questionnaire_id']);
      const formUtils = getFormUtils(form);
      const qn = formUtils.getQuestion(props.xAxisQuestionId);
      if (qn) {
        return qn.text;
      }
      return props.xAxisQuestionId;
    }
    return '';
  }, [props.chartId, props.xAxisQuestionId, dataPoints]);

  const xAxisText = React.useMemo(() => {
    return `
      ${getXAxisQuestion ? `${getXAxisQuestion} :` : ''}
      ${props.xAxis}
      ${props.stack && props.stack !== props.xAxis ? `- ${props.stack}` : ''}
    `;
  }, [props]);

  const form = React.useMemo(() => {
    return forms.find(f => f.ref === props.formId);
  }, [props.formId]);

  const getTableRowName = (row: string, dp: DataPoint) => {
    const subfrmfld = dp['subfrmfld'];
    if (subfrmfld) {
      const parts = subfrmfld.split('_');
      const parentForm = forms.find(f => f.ref === parts[0]);
      if (parentForm) {
        const fUtils = getFormUtils(parentForm);
        const qn = fUtils.getQuestion(parts[1]);
        if (qn) {
          const rows = qn.table?.rows?.listItem;
          if (rows) {
            const r = rows.find(r => r.id === row);
            if (r) {
              return `${dp['parentName']} - ${r.value}`;
            }
          }
        }
      }
    }
    return `${dp['parentName']} - ${row}`;
  };

  const getBody = () => {
    const items = dataPoints.length > PAGE_SIZE ?
      dataPoints.slice(page * PAGE_SIZE, ((page + 1) * PAGE_SIZE)) : dataPoints;

    return (
      <>
        {form ? <label><strong>{getLocalization('form')} : {form.name}</strong></label> : null}<br />
        <label><strong>{getLocalization('xaxis')}</strong></label>
        {<p>{xAxisText}</p>}
        <p>{getLocalization('numberOfDataPoints')} : {dataPoints.length}</p>
        <ListGroup className="chart-data-point-list">
          {items.map(dp => (
            <ListGroup.Item key={`${props.chartId}-${props.xAxis}-${dp['_id']}`}>
              <a
                onClick={() => setSI(dp)}
                href={`#${dp.questionnaire_id}/${dp.id || '#'}/${dp._id}/false`}
              >{form.type === 'TABLE' ? getTableRowName(dp['Name'], dp) : dp['Name']}</a>
            </ListGroup.Item>
          ))}
        </ListGroup>
        {dataPoints.length > 15 && (
          <Pagination size="sm" className="justify-content-center mt-2">
            <Pagination.Prev
              onClick={() => changePage(-1)}
            />
            <Pagination.Item
              active
            >
              {`${page + 1}`}
            </Pagination.Item>
            <Pagination.Next
              onClick={() => changePage(1)}
            />
          </Pagination>
        )}
      </>
    );
  };

  return (
    <GenericModal
      visible={true}
      title={getLocalization('chartDataPoints')}
      confirmText={getLocalization('close')}
      onConfirm={close}
      body={getBody()}
      dialogClassName="large"
    />
  );
}

export const showChartModal = (
  chartId: string, xAxis: string, xAxisQuestionId: string, yAxis?: string, stack?: string,
  groupBy?: string, grouping?: string, formId?: string
) => {
  const modalProps: ModalInterface<ChartDataPointsModalProps> = {
    component: ModalComponentNames.ChartDataPointsModal,
    props: {
      chartId, xAxis, xAxisQuestionId, yAxis, stack, groupBy, grouping, formId
    }
  };
  (window as any).ReactAppBridge.ReduxActions.navigateAddModal(modalProps);
};
