import * as React from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { getLocalization } from '../../../../../global/global';
import { pad } from '../../../../../utils/utils';

interface Props {
  setData: (fileData: Blob) => void;
  onStartRecording: () => void;
  onEndRecording: () => void;
  disabled: boolean;
}

export default function AudioRecorder(props: Props) {

  const mediaRecorder = React.useRef<MediaRecorder>();
  const chunks = React.useRef<Blob[]>([]);

  const [recording, setRecording] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const [canRecord, setCanRecord] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    if (!initialized) {
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: true}).then((stream) => {
          setInitialized(true);
          setCanRecord(true);
          mediaRecorder.current = new MediaRecorder(
            stream, MediaRecorder.isTypeSupported('audio/webm') ? { mimeType: 'audio/webm' } : {}
          );
          mediaRecorder.current?.onstop = () => {
            const blob = new Blob(chunks.current, { type: mediaRecorder.current?.mimeType });
            props.setData(blob);
            chunks.current = [];
          };
          mediaRecorder.current?.ondataavailable = (e) => {
            chunks.current.push(e.data);
          };
        }, () => {
          setInitialized(true);
          setCanRecord(false);
        });
      }
    }
    return () => {
      if (recording) {
        mediaRecorder.current?.stop();
      }
    };
  }, [props]);

  const startStop = () => {
    if (!recording) {
      setRecording(true);
      props.onStartRecording();
      mediaRecorder.current?.start();
    } else {
      setRecording(false);
      props.onEndRecording();
      mediaRecorder.current?.stop();
    }
  };

  return (
    <Form.Group>
      {!canRecord ? (
        <Alert variant={'warning'}>{
          getLocalization(canRecord === false ? 'audioRecordingNotSupport' : 'audioRecordingNotGranted')
        }</Alert>
      ) : (
        <Button
          variant={recording ? "danger" : "primary"}
          onClick={startStop}
          size="sm"
          disabled={props.disabled}
        >
          <i className="fa fa-microphone mr-1" aria-hidden="true" />
          {getLocalization(recording ? 'stop' : 'record')}
          {recording ? (
            <Timer recording={recording} />
          ) : null}
        </Button>
      )}
    </Form.Group>
  );
}

interface TimerProps {
  recording: boolean;
  time?: number;
}

const Timer = (props: TimerProps) => {

  const [timer, setTime] = React.useState(0);

  React.useEffect(() => {
    if (!props.time) {
      const interval = setInterval(() => setTime(timer => timer + 1), 1000);
      return function cleanup() {
        clearInterval(interval);
      };
    }
    return;
  }, [props.recording]);

  const formatTime = () => {
    const time = props.time || timer;
    const seconds = time % 60;
    const minutes = time > 3600 ? time % 3600 : 0;
    return `${pad(minutes)}:${pad(seconds)}`;
  };

  return (
    <span className="ml-2">{formatTime()}</span>
  );
};
