import * as React from 'react';
import GenericModal from '../Modals/GenericModal';
import { getTableHeaderText } from './Columns';

interface Props {
  value: string;
  row?: any;
  question: any;
}

export default function MoreTextComponent(props: Props) {
  const [visible, setVisible] = React.useState(false);

  const modal = visible ? (
    <GenericModal
      visible={visible}
      onConfirm={() => setVisible(false)}
      body={<label dangerouslySetInnerHTML={{ __html: props.value}} />}
      confirmText={'Close'}
      title={getTableHeaderText(props.question)}
    />
  ) : null;

  return (
    <React.Fragment>
      {modal}&nbsp;&nbsp;
      <a
        onClick={() => setVisible(true)}
        style={{ textDecoration: 'underline', cursor: 'pointer', color: '#32b8c4' }}
      >
        more...
      </a>
    </React.Fragment>
  );
}
