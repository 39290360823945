import './Style.scss';
import * as React from 'react';
import { Col, Dropdown, Form, FormControl, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'index';
import { fetchPOI } from 'actions/pois';
import { DataPoint } from 'Interfaces/DataPoint';
import { ChartModel } from 'Interfaces/ChartInterface';
import { getLocalization, globalWindow } from 'global/global';
import { QueryFiltersInterface } from 'reducers/filtersMenuReducer';

interface Props {
  children: JSX.Element;
  selected: string[];
  model: ChartModel;
  updateModel: (model: Partial<ChartModel>) => void;
}

export default function RadarChartLines(props: Props) {
  const dispatch = useAppDispatch();
  const filtersMenu = useAppSelector(state => state.filtersMenu);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [dataPoints, setDataPoints] = React.useState<DataPoint[]>([]);
  const [filtered, setFiltered] = React.useState<DataPoint[]>([]);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const { selected, model } = props;

  React.useEffect(() => {
    const formIds = Object.keys(model.forms);
    if (!loading && !loaded && formIds.length > 0) {
      setLoading(true);
      const filters: QueryFiltersInterface[] = [];
      if (model.dataFilter === 'OWN') {
        filters.push({
          condition : 'IN', field : 'users', value : `${globalWindow.userID}`, operator : '0', fieldOperator : '1'
        });
      }
      const data = dispatch(fetchPOI(formIds[0], undefined, undefined, filters, undefined, 'id,Name'));
      data.then(res => res.json()).then(json => {
        setLoaded(true);
        setLoading(false);
        setDataPoints(json);
        setFiltered(json);
      }).catch(() => {
        setLoaded(true);
        setLoading(false);
      });
    }
  }, [model.forms, model.dataFilter]);

  React.useEffect(() => {
    setLoaded(false);
  }, [filtersMenu]);

  React.useEffect(() => {
    if (filter) {
      setFiltered(dataPoints.map(
        (dp) => `${dp['Name']}`.toLowerCase().search(filter.toLowerCase()) > -1 ? dp : null
      ).filter(dp => dp !== null));
    } else {
      setFiltered(dataPoints);
    }
  }, [filter]);

  const onDataPointSelected = (value, checked) => {
    const { model } = props;
    if (checked) {
      props.updateModel({ dataPoints: model.dataPoints.concat([value])});
    } else {
      props.updateModel({ dataPoints: model.dataPoints.filter(v => v !== value)});
    }
  };

  const onToggle = (isOpen, event, metadata) => {
    if (metadata.source === 'select') {
      setShowDropdown(true);
    } else {
      setShowDropdown(isOpen);
    }
  };

  const list = React.useCallback(() => {
    return filtered.map((dp, index) => {
      return (
        <Dropdown.Item as="div" key={`radar-chart-${dp['_id']}-${index}`}>
          <Form.Check
            id={`radar-chart-${dp['_id']}-${index}`}
            type={'checkbox'}
            label={dp['Name']}
            value={dp['id']}
            checked={selected.indexOf(`${dp['id']}`) > -1}
            disabled={selected.length === 5 && selected.indexOf(`${dp['id']}`) === -1}
            onChange={(e) => onDataPointSelected(e.target.value, e.target.checked)}
          />
        </Dropdown.Item>
      );
    });
  }, [filtered, selected]);

  return (
    <Col md={8}>
      <div className="btn-group chart-content-spacing">
        <Dropdown
          onToggle={onToggle}
          show={showDropdown}
        >
          <Dropdown.Toggle variant="primary"  size="sm" id="dropdown-basic">
            Add lines
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="radar-chart-filter-div">
              <ToggleButtonGroup
                type="radio"
                name="radar-chart-data-filter"
                value={model.dataFilter}
                onChange={(value) => {
                  props.updateModel({...model, dataFilter: value});
                  setLoaded(false);
                }}
                size="sm" className="mb-2"
              >
                <ToggleButton
                  id={`radar-chart-data-filter-own-btn`}
                  type="radio"
                  name="radar-chart-data-filter"
                  value={'OWN'}
                  checked={model.dataFilter === 'OWN'}
                >
                  {getLocalization('ownData')}
                </ToggleButton>
                <ToggleButton
                  id={`radar-chart-data-filter-all-btn`}
                  type="radio"
                  name="radar-chart-data-filter"
                  value={'ALL'}
                  checked={model.dataFilter === 'ALL'}
                >
                  {getLocalization('allData')}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className="radar-chart-filter-div">
              <FormControl
                name="filter"
                size="sm"
                value={filter}
                onChange={e => setFilter(e.target.value)}
              />
            </div>
            <div className="radar-chart-datapoints">
              {list()}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {props.children}
    </Col>
  );
}
