// import { IGridColumn, IGridSourceDataFields, jqx } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';
import * as React from 'react';
import {
  Comparator, customFilter, FILTER_TYPES, numberFilter, textFilter
} from 'react-bootstrap-table2-filter';
import { Type } from 'react-bootstrap-table2-editor';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { questionTypes } from 'Interfaces/Forms/QuestionInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import { OptionDataSource, TableColumnInterface } from 'Interfaces/Table/TableColumnInterface';
import { getLocalization } from '../../global/global';
import { Locations } from '../../Interfaces/LocationInterface';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { Users } from '../../Interfaces/User';
import { getLocationHierachyDepth, isNullOrUndefined } from '../../utils/utils';
import { QuestionInterface } from '../../Interfaces/Forms/QuestionInterface';
import DateFilter from './filters/DateRangeFilter';
import SelectFilter from './filters/SelectFilter';
import * as Renderers from './CellsRenderers';
import MultipleSelectEditor from './editors/MultipleSelectEditor';
import NumericEditor from './editors/NumericEditor';
import MoreTextComponent from './MoreTextComponent';

export const initColumnDataFields = (
  form: FormInterface, users: Users, clientPersist: ClientPersistInterface, forms: FormInterface[],
  locations?: Locations, tableQuestion?: QuestionInterface
) => {
  const columns: TableColumnInterface[] = [{ dataField: 'row_id', hidden: true }];

  const usersDataSource = users.map(user => ({ value: user.id, label: user.name}));

  initCoreFields(form, columns, users, usersDataSource);
  if (form.hasLocationHierarchy && locations && locations.length > 1) {
    initLocationHierarchy(locations, columns, clientPersist.locationLabels);
  }
  if (form.questionPage) {
    for (const page of form.questionPage) {
      addColumnTypes(
        columns, page.question, form.schemaVersion, usersDataSource, form.type === 'TASKFORM', tableQuestion
      );
    }
  }
  if (form.question) {
    addColumnTypes(
      columns, form.question, form.schemaVersion, usersDataSource, form.type === 'TASKFORM', tableQuestion
    );
    if (form.isChild && form.type === 'TABLE') {
      columns.forEach(column => {
        // @ts-ignore
        column.editable = false;
        // @ts-ignore
        column.filter = undefined;
        // @ts-ignore
        column.filterRenderer = undefined;
      });
    }
  }
  if (form.type === 'TASKFORM') { // && form.isChild === true
    return sortTaskColumns(columns, forms);
  }
  return columns;
};

export const headerRenderer = (header) => {
  return `<div class="table-column-header"><strong>${header}</strong></div>`;
};

export const initCoreFields = (form, columns, users, usersDataSource) => {
  const filterOptions = {};
  for (const opt of users) {
    filterOptions[opt.value] = opt.label;
  }
  columns.push({
    dataField: 'user_id',
    text: 'Editor',
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    sort: true,
    hidden: true,
    editable: false,
    formatter: (cell) => {
      const user = users.find( l => l.id === cell);
      return Renderers.cellTemplate({ value: `${user ? user.name : cell}` });
    },
    filter: customFilter({ type: FILTER_TYPES.MULTISELECT }),
    filterRenderer: (onFilter, column) => {
      return (<SelectFilter onFilter={onFilter} column={column} options={usersDataSource}/>);
    }
  });

  if (form.hasCoordinates) {
    columns.splice(1, 0, {
      dataField: 'Coordinates',
      text: 'GPS Coordinates',
      classes: 'default-column-width',
      headerClasses: 'default-column-width header-cell',
      hidden: true
    });
  }
};

export const initLocationHierarchy = (locations, columns, locationLabels) => {
  let i = 1;
  const depth = getLocationHierachyDepth(locations) + 1;
  while (i <= depth) {
    if (locationLabels[i - 1]) {
      columns.push({
        dataField: `location${i}`,
        text: locationLabels[i - 1] ? locationLabels[i - 1] : `Location ${i}`,
        classes: 'default-column-width',
        headerClasses: 'default-column-width header-cell',
        sort: true,
        editable: false,
        formatter: (cell) => {
          const location = locations.find( l => `${l.key}` === `${cell}`);
          return Renderers.cellTemplate({ value: `${location ? location.title : ''}` });
        }
      });
    }
    i++;
  }
};

export const hasSubquestions = (question): boolean => {
  if (question.triggerValues && question.triggerValues.triggerValue) {
    for (const triggerValue of question.triggerValues.triggerValue) {
      if (
        triggerValue.action &&
        triggerValue.action.subQuestions &&
        triggerValue.action.subQuestions.question.length > 0
      ) {
        return true;
      }
    }
  }
  return false;
};

export const setHeaderTextFormatter = (columnDataField) => {
  if (columnDataField.text && columnDataField.text.length > 50) {
    columnDataField.headerFormatter = (column, colIndex, components) => (
      <React.Fragment>
        <span title={column.text}>{column.text.substring(0, 50)}...</span>
        {components.sortElement}
        {components.filterElement}
      </React.Fragment>
    );
  }
};

/**
 * Sets whether a column is editable or not.
 * A question is not editbale if
 *  - is referred to by a calculated or skip question
 *  - is a calculated (has a script)
 *  - is a lookup which refers to a form.
 * @param question  - the given question
 * @param columnDataField - the column definition.
 */
export const setEditable = (
  question: QuestionInterface,
  columnDataField: TableColumnInterface,
  schemaVersion: number,
  usersDataSource: OptionDataSource[]
) => {
  if (question.evaluate && question.evaluate.length > 0 && question.evaluate !== 'undefined') {
    columnDataField.editable = false;
    setHeaderTextFormatter(columnDataField);
  } else if (question.script && question.script.length > 0 || hasSubquestions(question)) {
    columnDataField.editable = false;
    setHeaderTextFormatter(columnDataField);
  } else {
    columnDataField.headerFormatter = (column, colIndex, components) => (
      <React.Fragment>
        <span className="fa fa-edit" />
        {column.text.length > 50 ?
          (<span title={column.text}>{column.text.substring(0, 50)}...</span>) : column.text
        }
        {components.sortElement}
        {components.filterElement}
      </React.Fragment>
    );
  }
  switch (question.type) {
    case 'SelectOneQuestion':
    case 'StatusQuestion':
    case 'LikertScaleQuestion':
      columnDataField.editor = {
        type: Type.SELECT,
        options: getOptionDataSource(question)
      };
      break;
    case 'BooleanQuestion':
      columnDataField.editor = {
        type: Type.SELECT,
        options: [
          { value: '', label: ''},
          { value: true, label: 'Yes'},
          { value: false, label: 'No'}
        ]
      };
      break;
    case 'SelectMultipleQuestion':
      columnDataField.editorRenderer = (editorProps, value) => (
        <MultipleSelectEditor
          {...editorProps}
          value={value}
          options={getOptionDataSource(question)}
          question={question}
        />
      );
      break;
    case 'SelectUserQuestion':
    case 'UserQuestion':
      columnDataField.editorRenderer = (editorProps, value) => (
        <MultipleSelectEditor
          {...editorProps}
          value={value}
          options={usersDataSource}
          question={question}
        />
      );
      break;
    case 'DateQuestion':
      columnDataField.editor = {
        type: Type.DATE
      };
      break;
    case 'StringQuestion':
    case 'NameQuestion':
      columnDataField.editor = {
        type: Type.TEXTAREA
      };
      break;
    case 'IntQuestion':
    case 'FloatQuestion':
      columnDataField.editorRenderer = (editorProps, value) => (
        <NumericEditor
          {...editorProps}
          value={value}
          question={question}
        />
      );
      break;
    default:
  }
};

const hasImages = (question: QuestionInterface): boolean => {
  if (
    question.type === questionTypes.DIGITAL_SIGNATURE_QUESTION
    || (question.includeFile && question.accept && question.accept.indexOf('image') > -1)
  ) {
    return true;
  }
  return false;
};

const isShownInTable = (question) => {
  const visibleHiddenField = ['parentRowId'];
  return question.type === 'NameQuestion' || (!question.inVisible && !question.deleted)
  || visibleHiddenField.indexOf(question.id) !== -1;
};

export const addColumnTypes = (
  columns,
  questions,
  schemaVersion: number | undefined,
  usersDataSource,
  isTaskForm: boolean,
  tableQuestion?: QuestionInterface
) => {
  let columnDataField;
  const inVisibleQuestions = [
    'phoneEditTime', 'portalEditTime', 'portalEditEntry', 'entryTime', 'createdBy', 'ModifiedTime', 'modified'
  ];

  const isHidden = (qn, isTask) => {
    return isTask ? qn.hideInTaskView : (
      qn.type === 'NameQuestion' && tableQuestion && !tableQuestion.table?.rows?.listItem ? true : qn.hideInTable
    );
  };

  for (const question of questions) {
    if (isShownInTable(question)) {
      columnDataField = getColumnDefinition(question, schemaVersion, usersDataSource, tableQuestion);
      if (columnDataField) {
        columnDataField.hidden = isHidden(question, isTaskForm);
        setEditable(question, columnDataField, schemaVersion, usersDataSource);
        if (question.type === 'NameQuestion') {
          columnDataField.classes = 'name-column-width';
          columnDataField.headerClasses = 'name-column-width header-cell';
          columns.splice(0, 0, columnDataField);
          if (hasImages(question)) {
            const imageColumn = defineImageColumn(question);
            columns.splice(1, 0, imageColumn);
          }
          continue;
        } else if (question.type === 'LookupValuesQuestion' && columnDataField.dataField.endsWith('_name')) {
          const lookupColumn = defineStringColumn(question, tableQuestion);
          // @ts-ignore
          lookupColumn.hidden = true;
          columns.push(lookupColumn);
        }
        columns.push(columnDataField);
        if (hasImages(question)) {
          const imageColumn = defineImageColumn(question);
          columns.push(imageColumn);
        }
      }
      if (question.triggerValues && question.triggerValues.triggerValue) {
        for (const triggerValue of question.triggerValues.triggerValue) {
          if (triggerValue.action.subQuestions) {
            // if a question has subquestions, it should not be editable.
            if (columnDataField) {
              columnDataField.editable = false;
            }
            addColumnTypes(
              columns, triggerValue.action.subQuestions.question, schemaVersion, usersDataSource, isTaskForm
            );
          }
        }
      }
    } else if (inVisibleQuestions.indexOf(question.id) !== -1) {
      columnDataField = getColumnDefinition(question, schemaVersion, usersDataSource, tableQuestion);
      if (columnDataField) {
        columnDataField.hidden = true;
        columnDataField.editable = false;
        if (question.id === 'modified' || question.id === 'ModifiedTime') {
          columnDataField.dataField = 'modified';
          // columnDataField.dataField.name = 'modified';
          columnDataField.text = getLocalization('lastmodified');
          columnDataField.formatter = Renderers.dateTimeCellRenderer;
        }
        if (question.id === 'portalEditEntry' || question.id === 'entryTime') {
          columnDataField.formatter = Renderers.dateTimeCellRenderer;
        }
        columns.push(columnDataField);
      }
    }
  }
};

export const getColumnDefinition = (
  question: QuestionInterface,
  schemaVersion: number,
  usersDataSource: OptionDataSource[],
  tableQuestion?: QuestionInterface
) => {
  let columnDataField: TableColumnInterface | null = null;

  const getLookupColumn = () => {
    const cdf = defineStringColumn(question, tableQuestion);
    if (question.lookupValue && question.lookupValue?.indexOf(';') < 0) {
      cdf.dataField = `${question.id}_name`;
    }
    return cdf;
  };

  const isStringQuestion = (type: string) => {
    switch (type) {
      case 'StringQuestion':
      case 'CalculatedValueQuestion':
      case 'NameQuestion':
      case questionTypes.EMAIL_QUESTION:
      case questionTypes.PHONE_NUMBER_QUESTION:
        return true;
      default:
        return false;
    }
  };

  switch (question.type) {
    case 'IntQuestion':
    case 'FloatQuestion':
      columnDataField = defineNumericColumn(question, usersDataSource);
      break;
    case 'TimeQuestion':
    case 'TimeOfDayQuestion':
    case 'DateQuestion':
      columnDataField = defineDateTimeColumn(question);
      break;
    case 'BooleanQuestion':
      columnDataField = defineBooleanColumn(question);
      break;
    case 'SelectOneQuestion':
    case 'StatusQuestion':
    case 'LikertScaleQuestion':
      columnDataField = defineRadioColumn(question, getOptionDataSource(question));
      break;
    case 'SelectMultipleQuestion':
      columnDataField = defineCheckboxColumn(question, getOptionDataSource(question));
      break;
    case 'SelectUserQuestion':
    case 'UserQuestion':
      columnDataField = defineCheckboxColumn(question, usersDataSource);
      break;
    case 'LookupValuesQuestion': {
      columnDataField = getLookupColumn();
      break;
    } case 'PicturesQuestion':
      columnDataField = defineImageColumn(question);
      break;
    case 'FileQuestion':
      if (hasImages(question)) {
        columnDataField = defineImageColumn(question);
      }
      break;
    default:
      if (isStringQuestion(question.type)) {
        columnDataField = defineStringColumn(question, tableQuestion);
      }
  }
  return columnDataField;
};

export const getTableHeaderText = (question: QuestionInterface) => {
  return question.text ? question.text.replace(/(#)*(\**)*/g, '') : question.id;
};
/*
 * Defines a numeric column
 */
export const defineNumericColumn = (question: QuestionInterface, users: OptionDataSource[]) => {
  if (question.id === 'createdBy') {
    return defineCheckboxColumn(question, users);
  }

  const column: TableColumnInterface = {
    dataField: question.id,
    text: getTableHeaderText(question),
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    formatter: Renderers.getNumericCellRenderer(question),
    sort: true,
    filter: numberFilter({ numberStyle: { width: 'calc(100% - 10px)'}})
  };
  return column;
};

/*
 * Define a date column.
 */
export const defineDateTimeColumn = (question) => {
  const width = question.type === 'DateQuestion' ? 'date-column-width header-cell' : 'default-column-width header-cell';
  const formatter = question.type === 'DateQuestion' ? Renderers.dateCellRenderer :
    Renderers.timeCellRenderer;

  const id = question.id === 'ModifiedTime' ? 'modified' : question.id;
  const column: TableColumnInterface = {
    dataField: question.id,
    text: getTableHeaderText(question),
    classes: width ,
    headerClasses: width,
    sort: true,
    formatter: formatter,
    formatExtraData : { question },
    filter: customFilter({
      type: FILTER_TYPES.DATE,
      comparator: Comparator.GE,
      onFilter: (filterVal: string, data: DataPoint[]) => {
        if (filterVal) {
          const getStart = (val: number | string) => {
            const start = new Date(val);
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            return start;
          };
          return data.filter(dp => dp[id]
            && getStart(dp[id]).getTime() >= getStart(filterVal[0]).getTime()
            && new Date(dp[id]).getTime() <= new Date(filterVal[1]).getTime()
          );
        }
        return data;
      }
    }),
    filterRenderer: (onFilter, column: TableColumnInterface) => {
      return (<DateFilter onFilter={onFilter} column={column} />);
    }
  };

  return column;
};

export const defineStringColumn = (question, tableQuestion) => {
  const column = {
    dataField: question.id,
    text: getTableHeaderText(question),
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    sort: true,
    formatter: Renderers.getStringCellRenderer(question, tableQuestion),
    filter: textFilter(),
  };
  return column;
};

export const defineImageColumn = (question) => {
  const { id, type } = question;
  return {
    dataField: `${question.id}_images`,
    text: `${
      getTableHeaderText(question)}
      ${type === questionTypes.DIGITAL_SIGNATURE_QUESTION ?
    ` ${getLocalization('signature')} ` : ` ${getLocalization('images')}`}`,
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    sort: true,
    formatter: Renderers.imagesCellRenderer(question),
    questionId: id
  };
};

export const defineBooleanColumn = (question) => {
  const options = [
    { value: true, label: 'Yes'},
    { value: false, label: 'No'}
  ];
  const column =  {
    dataField: question.id,
    text: getTableHeaderText(question),
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    options: options,
    sort: true,
    formatter: (cell) => {
      const text = cell === true || cell === 'true' ? 'Yes' : cell === false || cell === 'false' ? 'No' : '';
      return Renderers.cellTemplate({ value: `${text}` });
    },
    filter: customFilter({ type: FILTER_TYPES.MULTISELECT }),
    filterRenderer: (onFilter, column) => {
      return (<SelectFilter onFilter={onFilter} column={column} options={options}/>);
    }// selectFilter({ options: options })
  };
  /**/
  return column;
};

export const defineCheckboxColumn = (question: QuestionInterface, dataSource: OptionDataSource[]) => {
  const filterOptions = {};
  for (const opt of dataSource) {
    filterOptions[opt.value] = opt.label;
  }
  const column: TableColumnInterface = {
    dataField: question.id,
    text: getTableHeaderText(question),
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    sort: true,
    formatter: (cell, row) => {
      if (cell) {
        const values = `${cell}`.split(',').map(v => v.trim());
        const selectedValues = dataSource.filter(
          d => values.indexOf(d.value) !== -1 || values.indexOf(`${d.value}`) !== -1
        );
        if (selectedValues) {
          const renderedText = selectedValues.map(v => v.label).join(',');
          if (renderedText.length > 50) {
            return Renderers.cellTemplate({
              value: (
                <span>
                  {renderedText.substring(0, 50)}
                  <MoreTextComponent value={renderedText} row={row} question={question} />
                </span>
              )
            });
          }
          return Renderers.cellTemplate({ value: `${renderedText}` });
        }
      }
      return Renderers.cellTemplate({ value: `${''}` });
    },
    filter: customFilter({ type: FILTER_TYPES.MULTISELECT }),
    filterRenderer: (onFilter, column) => {
      return (<SelectFilter onFilter={onFilter} column={column} options={dataSource}/>);
    }
    /* filter: multiSelectFilter({
      options: filterOptions
    })
    /*
    /*filtertype: 'checkedlist',
    filteritems: optionsDataSourceAdapter,
    width: 200,
    renderer: headerRenderer,
    cellsrenderer: (row, columnfield, value, defaulthtml, columnProperties) => {
      const data = dataSource.localdata;
      const values = value.split(',');
      const selectedValues = data.filter( d => values.indexOf(d.value) !== -1 || values.indexOf(`${d.value}`) !== -1);
      const renderedText = selectedValues.map(v => v.label).join(',');
      return Renderers.cellTemplate({ value: `${renderedText}` });
    }*/
  };
  return column;
};

export const defineRadioColumn = (question, dataSource) => {
  const column = {
    dataField: question.id,
    text: getTableHeaderText(question),
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    sort: true,
    formatter: (cell, row) => {
      const selectedValue = dataSource.find( option => option.value === cell);
      const renderedText =  selectedValue ? selectedValue.label : '';
      if (renderedText.length > 50) {
        return Renderers.cellTemplate({
          value: (
            <span>
              {renderedText.substring(0, 50)}<MoreTextComponent value={renderedText} row={row} question={question} />
            </span>
          )
        });
      }
      return Renderers.cellTemplate({ value: renderedText });
    },
    filter: customFilter({ type: FILTER_TYPES.MULTISELECT }),
    filterRenderer: (onFilter, column) => {
      return (<SelectFilter onFilter={onFilter} column={column} options={dataSource}/>);
    }
  };
  return column;
};

/*
 * Given a question, this function should return an array with key value for displaying options.
 */
export const getOptionDataSource = (question: QuestionInterface) => {
  const options: OptionDataSource[] = [];
  if (question.listItems) {
    const listItem = question.listItems.listItem;
    for (const li of listItem) {
      if (li.id) {
        options.push({ value: li.id, label: li.value });
      } else {
        options.push({ value: li.value, label: li.value });
      }
    }
    return options;
  }
  return options;
};

export const sortTaskColumns = (columns: (TableColumnInterface | null)[], forms) => {
  const taskColumns: TableColumnInterface[] = [];
  let i = 0;
  const visibleFields = [
    'Name', 'questionnaire_id', 'parentRowId', 'taskstatus', 'assignedto', 'task', 'duedate',
    'donedate', 'doneby', 'approveddate', 'toapprove', 'approvedby', 'scheduleddate', 'startdate'
  ];
  columns.push({
    dataField: 'questionnaire_id',
    text: getLocalization('templateName'),
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    sort: true,
    filter: textFilter(),
    formatter: (cell) => {
      const form = forms.find( f => `${f.ref}` === `${cell}`);
      return Renderers.cellTemplate({ value: `${form ? form.name : ''}` });
    }
  });
  let hasStartDate = false;
  while (i < columns.length) {
    const column = columns[i];
    if (!column) {
      i++;
      continue;
    }
    switch (column.dataField) {
      case 'Name':
        taskColumns[0] = column;
        taskColumns[0].text = getLocalization('taskName');
        columns[i] = null;
        break;
      case 'questionnaire_id':
        taskColumns[1] = column;
        columns[i] = null;
        break;
      case 'parentRowId':
        taskColumns[2] = column;
        column.dataField = 'parentName';
        column.text = getLocalization('parentRowId');
        column.filter = textFilter();
        column.editable = false;
        columns[i] = null;
        break;
      case 'taskstatus':
        taskColumns[3] = column;
        column.editable = false;
        columns[i] = null;
        break;
      case 'assignedto':
        taskColumns[4] = column;
        column.editable = false;
        columns[i] = null;
        break;
      case 'task':
        taskColumns[5] = column;
        columns[i] = null;
        break;
      case 'duedate':
        taskColumns[6] = column;
        columns[i] = null;
        break;
      case 'startdate':
        hasStartDate = true;
        column.editable = false;
        column.text = getLocalization('startDate');
        break;
      case 'Coordinates':
      case 'assignto':
        column.hidden = true;
        break;
      case 'user_id':
        column.text = getLocalization('editor');
        break;
      default:
        /* if (visibleFields.indexOf(column.dataField) === -1) {
          column.hidden = true;
        }*/
        if (['donedate', 'doneby', 'approveddate', 'approvedby', 'startdate'].indexOf(column.dataField) !== -1) {
          column.editable = false;
        }
    }
    if (visibleFields.indexOf(column.dataField) !== -1) {
      column.hidden = false;
    }
    const ignoreTexts = ['parentName', 'Name', 'user_id', 'modified', 'formName'];
    if (getLocalization(column.dataField) !== `[${column.dataField}]` && ignoreTexts.indexOf(column.dataField) === -1
    ) {
      column.text = getLocalization(column.dataField);
    }
    i++;
  }
  if (!hasStartDate) {
    taskColumns.push(defineDateTimeColumn({
      id: 'startdate',
      text: getLocalization('startDate'),
      type: 'DateQuestion'
    }));
  }
  return taskColumns.concat(columns).filter( c => !isNullOrUndefined(c));
};
