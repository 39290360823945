import { DashboardDataPointInterface } from 'Interfaces/DashBoardInterface';
import './Style.scss';
import { DataPoint } from 'Interfaces/DataPoint';
import { StateInterface } from 'Interfaces/StateInterface';
import { addDataPointsToDashboard } from 'actions/dashboardActions';
import { fetchPOI } from 'actions/pois';
import { getLocalization } from 'global/global';
import { useAppDispatch, useAppSelector } from 'index';
import * as React from 'react';
import { Form, FormCheck, ListGroup, ListGroupItem } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getLocationHierarchyQuerySelector } from 'reducers/filtersMenuReducer';
import { formsForFiltersSelector } from 'reducers/formsReducer';
import { createRequestFilters } from 'utils/utils';
import GenericModal from 'views/Modals/GenericModal';

export interface AddDataPointToDashboardModalProps {
  onClose: () => void;
  dashboardId: number;
  dataPoints: DashboardDataPointInterface[];
}

export default function AddDataPointToDashboardModal(props: AddDataPointToDashboardModalProps) {

  const forms = useSelector((state: StateInterface) => {
    return formsForFiltersSelector(state);
  });
  const locationHierarchyQuery = useAppSelector(state => getLocationHierarchyQuerySelector(state));
  const filtersMenu = useAppSelector(state => state.filtersMenu);
  const dispatch = useAppDispatch();
  const [formId, setFormId] = React.useState('');
  const abortController = React.useRef<AbortController>(new AbortController());
  const [validate, setValidate] = React.useState(false);
  const [dataPoints, setDataPoints] = React.useState<null | DataPoint[]>(null);
  const [filtered, setFiltered] = React.useState<DataPoint[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [filter, setFilter] = React.useState('');

  React.useEffect(() => {
    if (formId) {
      const dps = props.dataPoints.map(d => d.questionnaireId === formId ? d.id : null);
      const request = dispatch(fetchPOI(
        formId,
        abortController.current.signal,
        undefined,
        createRequestFilters(filtersMenu),
        locationHierarchyQuery,
        ['id', 'Name'].join(',')
      ));
      request.then(res => res.json()).then(json => {
        if (dps.length > 0) {
          const fil = json.filter(d => dps.indexOf(`${d.id}`) === -1);
          setDataPoints(fil);
          setFiltered(fil);
        } else {
          setDataPoints(json);
          setFiltered(json);
        }
      }).catch(e => {
        console.log(e);
      });
    }
    setSelected([]);
    setFiltered([]);
  }, [formId]);

  React.useEffect(() => {
    if (filter === '') {
      setFiltered(dataPoints || []);
    } else {
      setFiltered(dataPoints ? dataPoints.filter(dp => `${dp['Name']}`.search(filter) > -1) : []);
    }
  }, [filter]);

  const onDataPointSelected = (e) => {
    if (e.target.checked) {
      setSelected(selected.concat([e.target.value]));
    } else {
      setSelected(selected.filter(s => s !== e.target.value));
    }
  };

  const onSave = () => {
    if (formId === '') {
      setValidate(true);
      return;
    }
    void dispatch(addDataPointsToDashboard({
      dashboardId: props.dashboardId, dataPoints: selected.map(s => ({ questionnaireId: formId, id: s }))
    }));
    props.onClose();
  };

  return (
    <GenericModal
      visible={true}
      title={getLocalization('addDataPoint')}
      cancel={props.onClose}
      cancelText={getLocalization('cancel')}
      confirmText={getLocalization('add')}
      onConfirm={onSave}
      body={(
        <>
          <Form.Group>
            <Form.Label>{getLocalization('selectform')}</Form.Label>
            <Form.Control
              as='select'
              value={formId}
              onChange={(e) => setFormId(e.target.value)}
              size='sm'
              isInvalid={validate && formId === ''}
            >
              <option value={''}>{getLocalization('selectOne')}</option>
              {forms.map(form => (
                <option value={form.ref} key={`dashbord-form-${props.dashboardId}-${form.ref}`}>
                  {form.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {dataPoints ? (
            <>
              <Form.Control
                name="filter"
                value={filter}
                size="sm"
                onChange={(e) => setFilter(e.target.value)}
              />
              <ListGroup className="dashboard--add-data-point-list">
                {
                  filtered.map((dp) => {
                    return (
                      <ListGroupItem key={`dashboard-data-point-check-${dp.id}`}>
                        <FormCheck
                          id={`dashboard-data-point-check-${dp.id}`}
                          label={dp['Name']}
                          checked={selected.includes(`${dp.id}`)}
                          value={`${dp.id}`}
                          onChange={onDataPointSelected}
                        />
                      </ListGroupItem>
                    );
                  })
                }
              </ListGroup>
            </>
          ) : null}
        </>
      )}
    />
  );
}
