import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './TableComponent.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { Button } from 'react-bootstrap';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
// import overlayFactory from 'react-bootstrap-table2-overlay';
import FormUtils from 'views/SingleInstance/utils/FormUtils';
import { getFormUtils } from 'views/SingleInstance/utils/FormUtilsHolder';
import { MODULES } from 'Interfaces/ModuleSelectionInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { LooseObject } from '../../Interfaces/LooseObject';
import { ConfirmationModal } from '../Modals/ConfirmationModal';
import { isNullOrUndefined, userPermissionsChanged } from '../../utils/utils';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { LoadingComponent } from '../components/LoadingComponent';
import { getLocalization, globalWindow } from '../../global/global';
import HistoryTableContainer from './HistoryTableContainer';
import { initColumnDataFields } from './Columns';
import ColumnVisibilityComponent from './ColumnVisibilityComponent';
// import { getTableFilters, getTableSorts } from './utils';
// import { createRequestFilters, getLocationQuery } from '../../utils/utils';
import { filtersChanged, formFilterChanged } from './utils';
import { ExpandRowComponent } from './ExpandRowComponent';
import { TableProps } from './TableContainer';
import ReportButton from './ReportButton';
const ExcelIcon = require('./images/Excel.png');

interface State {
  columns: LooseObject[];
  data: DataPoint[];
  tempData: DataPoint[];
  clientPaginate: boolean;
  selected: string[];
  confirmDelete: boolean;
  showHistory: boolean;
  page: number;
  sizePerPage: number;
  totalSize: number;
  filtersMenu: FiltersMenuInterface;
  sort: Sort | undefined;
  loading: boolean;
  showOverdue: boolean;
  // source: any;
}

interface Sort {
  dataField: string;
  order: string;
}

const className = 'TableComponent';

export default class TableComponent extends React.Component<TableProps, State> {
  private grid = React.createRef<BootstrapTable>();
  private fields: string[] = ['draft_poi'];
  private filters = undefined;
  private abortController: AbortController = new AbortController();
  private formUtils: FormUtils;
  // private showHideDropDownSource = [];
  // private columns;
  // private dataAdapter: any;
  constructor(props: TableProps) {
    super(props);
    const columnDataFields = initColumnDataFields(
      props.model, props.users, props.clientPersist, props.forms, props.locationHierarchy, props.tableQuestion
    );
    // this.columns = columnDataFields;
    // this.getFields(columnDataFields);
    // this.initShowHideColumns(columnDataFields.columns);
    this.formUtils = getFormUtils(props.model);
    this.state = {
      columns: columnDataFields,
      data: props.data || [],
      clientPaginate: this.isClientPaginate(columnDataFields), // props.model.clientPaginate
      selected: [],
      confirmDelete: false,
      showHistory: false,
      page: 1,
      sizePerPage: 10,
      totalSize: 0,
      filtersMenu: props.filtersMenu,
      sort: undefined,
      loading: false,
      showOverdue: false,
      tempData: []
    };
  }

  @bind
  private isClientPaginate(columnDataFields) {
    const { model } = this.props;

    const estimateCount = columnDataFields.length * model.count;
    return !(estimateCount > 25000);
  }

  /* @bind
  private getFields(columns) {
    for (const column of columns) {
      let field;
      if (column.dataField) {
        if (column.dataField.endsWith('Name') && column.dataField.length > 4) {
          field = column.dataField.substring(0, column.dataField.length - 4);
        } else if (column.dataField.endsWith('_name') && column.dataField.length > 4) {
          field = column.dataField; // #.substring(0, col.name.length - 5)
        } else {
          field = column.dataField;
        }
        this.fields.push(field);
      }
    }
  }*/

  /* @bind
  private dataLoaded(data) {
    const { clientPaginate } = this.state;

    if (clientPaginate) {
      this.setState((prevState, prevProps) => ({ data: [...prevState.data, ...data] }));
    } else {
      let size = 0;
      if (data && data.length > 0 && data[0].totalCount) {
        size = data[0].totalCount;
      }
      this.setState((prevState, prevProps) => ({
        data: data, totalSize: size, page: this.currentPage, sizePerPage: this.currentPageSize
      }));
    }
  }*/

  public componentDidMount() {
    const { model } = this.props;

    if (model.type === 'TASKFORM' && this.props.selectedModule === MODULES.TASKS.toString()) {
      this.loadTasks();
    } else if (model.type === 'TABLE') {
      return;
    } else {
      const { clientPaginate } = this.state;
      if (clientPaginate) {
        this.loadData();
      } else {
        this.loadData(0, 10);
      }
    }
  }

  public componentDidUpdate(prevProps) {
    const { model } = this.props;
    if (filtersChanged(prevProps.filtersMenu, this.props.filtersMenu) ||
      userPermissionsChanged(prevProps.clientPersist, this.props.clientPersist) ||
      (model.type === 'TASKFORM' && formFilterChanged(prevProps.filtersMenu, this.props.filtersMenu))) {
      this.abortController.abort();
      this.abortController = new AbortController();
      const { model } = this.props;
      if (!this.state.clientPaginate) {
        this.loadData(0, this.state.sizePerPage);
      } else if (model.type === 'TASKFORM') {
        this.loadTasks();
      } else {
        this.loadData();
      }
      this.setState({ data: [] });
    }
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  @bind
  private loadTasks() {
    const { forms } = this.props;
    forms.forEach(form => {
      if (form.type === 'TASKFORM') {
        const fetchDataRequest = this.props.fetchPOI(
          form.ref,
          this.abortController.signal,
          undefined,
          undefined,
          this.props.locationHierarchyQuery
        );
        this.processFetchData(fetchDataRequest, true);
      }
    });
  }

  @bind
  private loadData(offset?: number | undefined, limit?: number | undefined) {
    const { model } = this.props;
    const { filtersMenu } = this.state;
    const requestParams: string[] = [`tableView=1`];
    if (!isNullOrUndefined(offset) && !isNullOrUndefined(limit)) {
      requestParams.push(`off=${offset}`);
      requestParams.push(`limit=${limit}`);
    }

    const filters: any = [];
    const users = filtersMenu.selectedUsers.map((u) => u.id);
    if (users.length > 0) {
      filters.push({ condition : 'IN', field : 'users', value : users.join(','), operator : '0', fieldOperator : '1' });
    }
    if (filtersMenu.selectedDates.from && filtersMenu.selectedDates.from !== '') {
      filters.push({
        condition : 'GREATER_THAN_OR_EQUAL',
        field: filtersMenu.selectedDates.filterBy,
        value: filtersMenu.selectedDates.from,
        operator: '0',
        fieldOperator: '1'
      });
    }
    if (filtersMenu.selectedDates.to && filtersMenu.selectedDates.to !== '') {
      filters.push({
        condition : 'LESS_THAN_OR_EQUAL',
        field: filtersMenu.selectedDates.filterBy,
        value: filtersMenu.selectedDates.to,
        operator: '0',
        fieldOperator: '1'
      });
    }
    // getLocationHierarchyQuerySelector()
    const request = this.props.fetchPOI(
      model.ref,
      this.abortController.signal,
      undefined,
      this.filters ? filters.concat(this.filters) : filters,
      this.props.locationHierarchyQuery,
      this.fields.join(','),
      this.state.sort,
      requestParams.length > 0 ? requestParams : undefined
    );
    this.setState({ loading : true });
    this.processFetchData(request, false);
  }

  @bind
  private processFetchData(request, taskForm: boolean) {
    request.then((response: Response) => response.json()).then((json) => {
      if (json && json.length > 0) {
        const totalSize = json[0].totalCount;
        if (!taskForm) {
          // this.setState({ data: json });
          const { columns } = this.state;
          for (const d of json) {
            for (const col of columns) {
              if (typeof d[col.dataField] === 'undefined') {
                d[col.dataField] = null;
              }
            }
          }
          this.setState({ data: json, totalSize, loading: false });
        } else {
          const { filtersMenu } = this.props;
          let data;
          if (filtersMenu.selectedForms.length > 0) {
            const forms = filtersMenu.selectedForms.map(sf => sf.ref);
            const visibleTasks = json.filter((task) => {
              return forms.indexOf(task.parentId) !== -1
                || forms.indexOf(task.mainDataPointFormId) !== -1
                || forms.indexOf(task.questionnaire_id) !== -1;
            });
            data = this.state.data.concat(visibleTasks);
          } else {
            data = this.state.data.concat(json);
          }
          this.setState({ data, loading: false });
        }
      } else {
        this.setState({ loading: false });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  @bind
  private showHistory(value) {
    this.setState({ showHistory: value });
  }

  @bind
  private deleteRows() {
    this.cancelDelete();
    const { model } = this.props;
    const { selected } = this.state;
    this.props.deletePOIs(selected, model.ref, this.poisDeleted);
  }

  @bind
  private poisDeleted(ids: string[]) {
    const newData = this.state.data.filter((poi) => {
      return ids.indexOf(poi.id) === -1;
    });
    // this.grid.current!.clearselection();
    this.setState({ data: newData });
  }

  @bind
  private cancelDelete() {
    this.setState({ confirmDelete: false });
  }

  @bind
  private confirmRowsDelete() {
    this.setState({ confirmDelete: true });
  }

  @bind
  private getHistory() {
    /*
    columnDataFields={{ columns: this.state.columns, dataFields: this.source.datafields }}*/
    if (this.state.selected.length === 1) {
      const selected = this.state.selected[0]; // this.grid.current!.selectionContext.selected;
      const data = this.state.data.find(d => d.id === selected);
      if (data) {
        return (
          <HistoryTableContainer
            closeHistory={this.showHistory}
            model={this.props.model}
            poiId={data.id}
            poiName={data.Name}
            columns={this.state.columns}
          />
        );
      }
    }
    return null;
  }

  @bind
  private sendReminders() {
    const { forms } = this.props;
    const { data } = this.state;
    const answerTables = {};
    forms.forEach(form => {
      answerTables[form.ref] = form.answerTable;
    });
    const { selected } = this.state;
    const reminders: any[] = data.map((poi) => {
      if (selected.indexOf(poi.id) !== -1) {
        if (poi.taskstatus === 'assigned' || poi.taskstatus === 'done') {
          return {
            rowId : poi.row_id ? `${poi.row_id}` : poi._id || null,
            questionnaireId : poi.questionnaire_id,
            answerTable : answerTables[poi.questionnaire_id],
            parentRowId : poi.parentRowId ? `${poi.parentRowId}` : null,
            parentId : poi.parentId,
            id: poi.id
          };
        }
      }
      return null;
    }).filter( d => d !== null);
    if (reminders.length > 0) {
      this.props.sendReminders({ customDataPointList: reminders });
    }
  }

  /**
   * Table Events
   *
   */
  @bind
  private onRowSelect(row, isSelect) {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.id)
      }));
    }
  }

  /**
   *  When select all is un/checked
   *
   */
  @bind
  private onSelectAll(isSelect, rows) {
    const ids = rows.map((r: DataPoint) => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  }

  @bind
  private onClientPaginateTableChange(type, newState) {
    console.log(type);
    console.log(newState);
  }

  @bind
  private onTableChange(type, newState) {
    let page = this.state.page;
    if (type === 'sort') {
      page = 1;
      const sort: Sort = { dataField: newState.sortField, order: newState.sortOrder };
      if (sort.dataField.endsWith('Name') && sort.dataField.length > 4) {
        sort.dataField = sort.dataField.substring(0, sort.dataField.length - 4);
      }
      this.setState({ sort, page: 1 }, () => {
        this.loadData((page - 1) * this.state.sizePerPage, this.state.sizePerPage);
      });
      return;
    } else if (type === 'filter') {
      this.setState({ page : 1, data: [] });
      page = 1;
      const tempFilters: any = [];
      const filters = newState.filters;
      for (const key in filters) {
        if (filters[key]) {
          const filter = filters[key];
          const tempFilter = {
            field: key,
            value: encodeURIComponent(filter.filterVal),
            condition: (key === 'user_id' || key === 'createdby') ? 'IN' : filter.comparator,
            operator: 1
          };
          if (filter.filterType === 'DATE') {
            tempFilter.value = encodeURIComponent(filter.filterVal.join(','));
          }
          if (Array.isArray(filter.filterVal)) {
            tempFilter.value = filter.filterVal.join(',');
          }
          tempFilters.push(tempFilter);
        }
      }
      this.filters = tempFilters;
    } else if (type === 'pagination') {
      return;
    }
    this.loadData((page - 1) * this.state.sizePerPage, this.state.sizePerPage);
  }

  @bind
  private onSizePerPageChange(sizePerPage, page) {
    this.setState({ page, sizePerPage }, () => {
      if (!this.state.clientPaginate) {
        this.loadData((this.state.page - 1) * this.state.sizePerPage, this.state.sizePerPage);
      }
    });
  }

  @bind
  private onPageChange(page, sizePerPage) {
    this.setState({ page, sizePerPage }, () => {
      if (!this.state.clientPaginate) {
        this.loadData((this.state.page - 1) * this.state.sizePerPage, this.state.sizePerPage);
      }
    });
  }

  @bind
  private updateColumns(columns) {
    this.setState({ columns });
  }

  @bind
  private beforeSaveCell(oldValue, newValue, row, column, done) {
    const { clientPersist } = this.props;
    // @ts-ignore
    if ((isNaN(oldValue) && isNaN(newValue) && oldValue !== newValue) || (Number(oldValue) !== Number(newValue))) {
      const newDataPoint = {...row};
      newDataPoint[column.dataField] = newValue;
      newDataPoint['modified'] = Date.now();
      delete newDataPoint['needs_editing'];
      newDataPoint['user_id'] = Number(clientPersist.user_id);
      const parameters = { copyComponents: 1 };
      const promise = this.props.savePOI(newDataPoint, parameters);
      this.onSaved(promise, newDataPoint, done);
      return { async: true };
    } else {
      done(false);
    }
    return;
  }

  @bind
  private onSaved(savePromise: Promise<Response>, dataPoint, done: (v: boolean) => void) {
    savePromise.then(response => response.json()).then(json => {
      if (json.rowId) {
        const newDataPoint: DataPoint = {...dataPoint, row_id: json.row_id || json.rowId };
        const newStateData = this.state.data.map((dp: DataPoint) => {
          if (dp.id === dataPoint.id) {
            return newDataPoint;
          }
          return dp;
        });
        this.setState({ data: newStateData }, () => done(true));
      }
    }).catch(error => {
      console.log(error);
      done(false);
    });
  }

  @bind
  private setTableQuestionValues(dataPoint: DataPoint) {
    const newStateData = this.state.data.map((dp: DataPoint) => {
      if (dp.id === dataPoint.id) {
        return {...dp, ...dataPoint};
      }
      return dp;
    });
    this.setState({ data: newStateData });
  }

  @bind
  private exportExcel() {
    const { model, clientPersist, filtersMenu } = this.props;
    const { columns } = this.state;
    const params: string[] = [
      `lang=${clientPersist.lang}`, `key=${globalWindow.pwrd}`, `userid=${globalWindow.userName}`
    ];
    params.push(`locs=${filtersMenu.selectedLocations.map(l => l.key).join(',')}`);
    params.push(`levels=${filtersMenu.selectedLocations.map(l => l.level ? Number(l.level) + 1 : 0).join(',')}`);
    params.push(`users=${filtersMenu.selectedUsers.map(u => u.id).join(',')}`);
    params.push(`from=${filtersMenu.selectedDates.from}`);
    params.push(`to=${filtersMenu.selectedDates.to}`);
    params.push(`dateFilterBy=${filtersMenu.selectedDates.filterBy}`);

    const hidden = columns.map((col: JSONInterface) =>
      col.hidden ? `${col.dataField}` : null).filter((col: string) => col !== null);
    params.push(`hiddenFields=${hidden.join(',')}`);

    if (this.grid.current) {
      if (this.grid.current.sortContext.state.sortOrder) {
        const sort = {
          sortorder: this.grid.current.sortContext.state.sortOrder,
          sortdatafield: this.grid.current.sortContext.state.sortColumn.dataField
        };
        params.push(`sort=${encodeURIComponent(JSON.stringify(sort))}`);
      }

      let filters;
      const condition = (filterType) => {
        switch (filterType) {
          case 'MULTISELECT':
            return 'IN';
          case 'SELECT':
            return 'IN';
          case 'NUMBER':
            return 'EQUAL';
          default:
            return 'CONTAINS';
        }
      };

      const getComparator = (cond) => {
        switch (cond) {
          case '=':
            return 'EQUAL';
          case '>':
            return 'GREATER_THAN';
          case '>=':
            return 'GREATER_THAN_OR_EQUAL';
          case '<':
            return 'LESS_THAN';
          case '<=':
            return 'LESS_THAN_OR_EQUAL';
          case '!=':
            return 'NOT_EQUAL';
          default:
            return 'EQUAL';
        }
      };

      if (this.grid.current.filterContext) {
        if (this.grid.current.filterContext.currFilters) {
          for (const key in this.grid.current.filterContext.currFilters) {
            const filter = this.grid.current.filterContext.currFilters[key];
            filters = filters || [];
            if (filter.filterType === 'DATE') {
              let condition2;
              if (filter.filterVal.length === 2) {
                condition2 =  {
                  value: filter.filterVal[1].format('yyyy-MM-DD'),
                  condition: 'LESS_THAN_OR_EQUAL',
                  field: key,
                  operator: 0,
                  fieldOperator: 1
                };
              }
              const fil = {
                value: filter.filterVal[0].format('yyyy-MM-DD'),
                condition: 'GREATER_THAN_OR_EQUAL',
                field: key,
                operator: 0,
                fieldOperator: 1,
                condition2
              };
              filters.push(fil);
            } else if (filter.filterType === 'NUMBER') {
              const value = filter.filterVal.number;
              filters.push({
                value: Number(value),
                condition: getComparator(filter.filterVal.comparator),
                field: key,
                operator: 0,
                fieldOperator: 1
              });
            } else {
              const value = Array.isArray(filter.filterVal) ? filter.filterVal.join(',') : filter.filterVal;
              filters.push({
                value: value,
                condition: condition(filter.filterType),
                field: key,
                operator: 0,
                fieldOperator: 1
              });
            }
          }
          if (filters) {
            params.push(`filters=${encodeURIComponent(JSON.stringify(filters))}`);
          }
        }
      }
    }
    const url = `/json/app/export/${model.id}/xlsx/${clientPersist.readOwn}/` +
    `${clientPersist.groupId}/${clientPersist.user_id}?` + params.join('&');
    globalWindow.open(url, '_blank');
  }

  @bind
  private showOverdue() {
    const { showOverdue, data, tempData } = this.state;
    if (!showOverdue) {
      const temporaryData = [...data];
      const end = new Date();
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      end.setMilliseconds(999);

      const overDue = data.filter(d =>
        (d['taskstatus'] === 'assigned' || d['taskstatus'] === 'inprogress')
        && d['duedate'] && new Date(d['duedate']).getTime() < end.getTime()
      );
      this.setState({ showOverdue: true, data: overDue, tempData: temporaryData });
    } else {
      this.setState({ showOverdue: false, data: tempData, tempData: [] });
    }
  }

  @bind
  private renderToolbar(): any {
    const { model, clientPersist, selectedModule } = this.props;
    const { showOverdue } = this.state;
    const viewer = clientPersist.roles.indexOf('viewer') !== -1;
    const enumerator = clientPersist.roles.indexOf('enumerator') !== -1;
    // const { columns } = this.state;
    const deleteButton = clientPersist.canDelete && selectedModule !== `${MODULES.TASKS}` ? (
      <button
        className="btn btn-danger btn-sm btn-delete"
        onClick={this.confirmRowsDelete}
        disabled={!(this.state.selected.length > 0)}
      >
        <i className="fa fa-trash" aria-hidden="true" />
      </button>
    ) : null;

    const historyButton = selectedModule !== `${MODULES.TASKS}` ? (
      <button
        className="btn btn-success btn-sm btn-history"
        onClick={() => this.showHistory(true)}
        disabled={!(this.state.selected.length === 1)}
      >
        <i className="fa fa-history" aria-hidden="true" />
      </button>
    ) : null;

    const remindersButton = model.type === 'TASKFORM' ? (
      <button
        className="btn btn-success btn-sm btn-reminders"
        onClick={this.sendReminders}
        disabled={!(this.state.selected.length > 0)}
      >
        <i className="fa fa-envelope" aria-hidden="true"/>{` ${getLocalization('sendReminders')}`}
      </button>
    ) : null;

    const exportButton = selectedModule !== `${MODULES.TASKS}` && !enumerator && !viewer ? (
      <button
        className="btn btn-success btn-sm"
        onClick={() => this.exportExcel()}
      >
        <img src={ExcelIcon} className={'excel-icon'}/>
      </button>
    ) : null;

    const overdue = selectedModule === `${MODULES.TASKS}` && (
      <Button
        size="sm"
        onClick={this.showOverdue}
        variant={showOverdue ? 'success' : 'dark'}
      >
        {getLocalization(showOverdue ? 'showAll' : 'showOverdue')}
      </Button>
    );

    const reportBtn = model.hasExcelTemplate || model.hasWordTemplate ? (
      <ReportButton model={model} />
    ) : null;

    return (
      <div className="table-toolbar">
        <span className="table-toolbar-name">
          {model.type === 'TASKFORM' && selectedModule === `${MODULES.TASKS}` ?
            getLocalization('tasksButton') : model.name}
        </span>
        <div className="table-toolbar-buttons">
          <ColumnVisibilityComponent
            columns={this.state.columns}
            updateColumns={this.updateColumns}
            questionnaireId={model.ref}
          />
          {exportButton}
          {reportBtn}
          {historyButton}
          {overdue}
          {deleteButton}
          {remindersButton}
        </div>
      </div>
    );
  }

  public static getDerivedStateFromProps(props: TableProps, state: State) {
    if (filtersChanged(props.filtersMenu, state.filtersMenu)) {
      return { filtersMenu: props.filtersMenu, data: [] };
    }
    return null;
  }

  public componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error);
    console.log(errorInfo);
  }

  public shouldComponentUpdate(nextProps, nextState: State) {
    if (this.state.loading !== nextState.loading) {
      return true;
    }
    if (this.state.showOverdue !== nextState.showOverdue) {
      return true;
    }
    if (this.state.data.length === nextState.data.length) {
      let difference = 0;
      const length = this.state.data.length;
      for (let i = 0; i < length; i++) {
        const dp = this.state.data[i];
        const dp1 = nextState.data[i];
        if (dp.row_id !== dp1.row_id) {
          difference++;
        }
        if (difference > 1) {
          return true;
        }
      }
      if (difference === 1) {
        return false;
      }
    }
    return true;
  }

  @bind
  private getTable() {
    const { clientPaginate, page, sizePerPage, totalSize, sort, loading } = this.state;
    const { clientPersist, forms, status, fetchPOI } = this.props;
    const expand = this.formUtils.getTableQuestionIds().length > 0;
    const formUtils = this.formUtils;
    const sizePerPageList = [5, 10, 20, 25, 30];
    const selectRow = {
      mode: 'checkbox',
      // clickToSelect: true,
      clickToEdit: true,
      selected: this.state.selected,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll
    };
    return clientPaginate ? (
      <BootstrapTable
        ref={this.grid}
        keyField="id"
        bootstrap4
        data={this.state.data}
        columns={this.state.columns}
        condensed
        wrapperClasses={`table-responsive table-view ${className}__table`}
        selectRow={selectRow}
        noDataIndication={'Table is Empty'}
        sort={sort}
        filter={filterFactory()}
        pagination={paginationFactory({
          page,
          sizePerPage,
          sizePerPageList,
          totalSize,
          onSizePerPageChange: this.onSizePerPageChange,
          onPageChange: this.onPageChange
        })}
        onTableChange={this.onClientPaginateTableChange}
        cellEdit={status !== 'EXPIRED' && clientPersist.tableViewEditEnabled && clientPersist.roles !== 'viewer' &&
          cellEditFactory({
            mode: 'dbclick', blurToSave: true, beforeSaveCell: this.beforeSaveCell
          })
        }
        expandRow={expand ?
          ExpandRowComponent({ formUtils, forms, fetchPOI, setTableQuestionValues: this.setTableQuestionValues }) :
          undefined
        }
      />
    ) : (
      <BootstrapTable
        ref={this.grid}
        keyField="id"
        bootstrap4
        data={this.state.data}
        columns={this.state.columns}
        condensed
        wrapperClasses={`table-responsive table-view ${className}__table`}
        selectRow={selectRow}
        noDataIndication={'Table is Empty'}
        sort={sort}
        filter={filterFactory()}
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize,
          sizePerPageList,
          onSizePerPageChange: this.onSizePerPageChange,
          onPageChange: this.onPageChange
        })}
        remote
        loading={loading}
        /* overlay={overlayFactory({
            spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(50, 184, 196, 0.5)'}) }
          })}*/
        onTableChange={this.onTableChange}
        cellEdit={status !== 'EXPIRED' && clientPersist.tableViewEditEnabled && clientPersist.roles !== 'viewer' &&
          cellEditFactory({
            mode: 'dbclick', blurToSave: true, beforeSaveCell: this.beforeSaveCell
          })
        }
        expandRow={expand ?
          ExpandRowComponent({ formUtils, forms, fetchPOI, setTableQuestionValues: this.setTableQuestionValues }) :
          undefined
        }
      />
    );
  }

  public render(): JSX.Element {
    const { confirmDelete, loading } = this.state;
    const { model } = this.props;
    const confirmDeleteModal = confirmDelete ? (
      <ConfirmationModal
        onConfirm={this.deleteRows}
        onClose={this.cancelDelete}
        localizations={
          {
            cancel: 'Cancel',
            confirm:'Delete',
            confirmStyle: 'danger',
            header: (
              <label>Confirm</label>
            ),
            body: (
              <div>Delete the selected POIs?</div>
            )
          }
        }
      />
    ) : null;

    const table = this.getTable();
    return (
      <div className={className} style={model.isChild ?  { width: `${window.innerWidth}px`} : {}}>
        {confirmDeleteModal}
        {this.renderToolbar()}
        {table}
        {this.state.showHistory && this.getHistory()}
        {loading && (<LoadingComponent />)}
      </div>
    );
  }
}
