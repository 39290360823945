import './Style.scss';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { DashboardInterface } from 'Interfaces/DashBoardInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { ChartModel } from 'Interfaces/ChartInterface';
import { ConfirmationModalProps } from 'views/Modals/ConfirmationModal';
import { ModalComponentNames, ModalInterface } from 'Interfaces/ModalInterface';
import FormComponent from 'views/SingleInstance/FormComponent';
import { DataPoint } from 'Interfaces/DataPoint';
import * as React from 'react';
import ReactGridLayout, { Responsive, WidthProvider  } from "react-grid-layout";
import { Button, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { fetchPOI } from 'actions/pois';
import { getLocalization } from 'global/global';
import { deleteDashboard, removeDataPointFromDashboard, setShowOnLogin, updateLayout } from 'actions/dashboardActions';
import { useAppDispatch, useAppSelector } from 'index';
import { navigateAddModal, navigateRemoveModal } from 'actions/navigationAction';
import DashboardChart from './DashboardChart';
import { AddChartToDashboardModalProps } from './AddChartToDashboardModal';
import { AddDataPointToDashboardModalProps } from './AddDataPointToDashboardModal';


const ResponsiveGridLayout = WidthProvider(Responsive);

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const abortController = React.useRef(new AbortController());
  const dashboard = useSelector((state: StateInterface) => state.dashboard);
  const charts = useSelector((state: StateInterface) => state.chartsMenu.charts);
  const dashboardMenu = useSelector((state: StateInterface) => state.dashboardMenu);
  const clientPersist = useAppSelector(state => state.clientPersist);
  const forms = useAppSelector((state: StateInterface) => state.forms.collection);

  const [selectedDashboard, setSelectedDashboard] = React.useState<DashboardInterface | undefined>(undefined);
  const [dashboardCharts, setDashboardCharts] = React.useState<ChartModel[]>([]);
  const [layout, setLayout] = React.useState<ReactGridLayout.Layout[]>([]);
  const [applyFilters, setApplyFilters] = React.useState(false);

  const [dataPoints, setDataPoints] = React.useState<DataPoint[]>([]);

  React.useEffect(() => {
    if (dashboardMenu.active) {
      const selected = dashboard.collection.find(d => `${d.id}` === dashboardMenu.active);
      setSelectedDashboard(selected);
    } else {
      setSelectedDashboard(undefined);
    }
  }, [dashboardMenu.active, dashboard]);

  React.useEffect(() => {
    const chart = charts.filter(c => selectedDashboard?.chartIds?.includes(Number(c.id)));
    if (selectedDashboard) {
      if (selectedDashboard.layout) {
        setLayout(selectedDashboard.layout);
        // resetLayout();
      } else {
        const lo = chart.map(c => `${c.id}`).concat(selectedDashboard.dataPoints.map(d => d.id)).map((c, i) => {
          return {
            i: `${c}`, x: (i % 2) * 2, y: Math.floor( i / 2), w: 1, h: 4
          };
        });
        setLayout(lo);
        void dispatch(updateLayout({ dashboardId: selectedDashboard.id, layout: JSON.stringify(lo)}));
      }
    }
    setDashboardCharts(chart);
  }, [selectedDashboard, charts]);

  React.useEffect(() => {
    setDataPoints([]);
    abortController.current.abort();
    abortController.current = new AbortController();
  }, [selectedDashboard?.id]);

  React.useEffect(() => {
    if (selectedDashboard) {
      const formsData = {};
      selectedDashboard.dataPoints.forEach(dp => {
        formsData[dp.questionnaireId] = formsData[dp.questionnaireId] || [];
        formsData[dp.questionnaireId].push(dp.id);
      });
      const formIds = Object.keys(formsData);
      let dps: DataPoint[] = [];

      const fetchData = async () => {
        for (const formId of formIds) {
          const form = forms.find(f => f.ref === formId);
          if (form) {
            const query = { id: formsData[formId].join(',') };
            const request = dispatch(fetchPOI(
              formId,
              abortController.current.signal,
              undefined,
              [],
              query,
            ));
            const json = await (await request).json();
            if (Array.isArray(json)) {
              dps = dps.concat(json);
            }
          }
        }
        setDataPoints(dps);
      };
      void fetchData();
    } else {
      setDataPoints([]);
    }
  }, [selectedDashboard?.dataPoints, forms]);


  const resetLayout = () => {
    if (selectedDashboard) {
      const chart = charts.filter(c => selectedDashboard?.chartIds?.includes(Number(c.id)))
        .concat(selectedDashboard.dataPoints);
      const lo = chart.map((c, i) => {
        return {
          i: `${c.id}`, x: (i % 2) * 2, y: Math.floor( i / 2), w: 1, h: 4
        };
      });
      setLayout(lo);
      void dispatch(updateLayout({ dashboardId: selectedDashboard.id, layout: JSON.stringify(lo)}));
    }
  };

  const addChart = () => {
    if (selectedDashboard) {
      const modalProps: ModalInterface<AddChartToDashboardModalProps> = {
        component: ModalComponentNames.AddChartToDashboardModal,
        props: {
          onClose: () => {
            dispatch(navigateRemoveModal(ModalComponentNames.AddChartToDashboardModal));
          },
          dashboardId: selectedDashboard.id,
          currentCharts: selectedDashboard.chartIds || []
        }
      };
      dispatch(navigateAddModal(modalProps));
    }
  };

  const addDataPoint = () => {
    if (selectedDashboard) {
      const modalProps: ModalInterface<AddDataPointToDashboardModalProps> = {
        component: ModalComponentNames.AddDataPointToDashboardModal,
        props: {
          onClose: () => {
            dispatch(navigateRemoveModal(ModalComponentNames.AddDataPointToDashboardModal));
          },
          dashboardId: selectedDashboard.id,
          dataPoints: selectedDashboard.dataPoints
        }
      };
      dispatch(navigateAddModal(modalProps));
    }
  };

  const onResizeStop = (layout: ReactGridLayout.Layout[], oldItem, newItem,
    placeholder, e: MouseEvent, element: HTMLElement) => {
    console.log(element);
  };

  const onUpdateLayout = (layout) => {
    if (selectedDashboard?.id && layout.length > 0) {
      const newLayout = layout.map(l => {
        if (l.h !== 4) {
          return {...l, h: 4};
        }
        return l;
      });
      void dispatch(updateLayout({ dashboardId: selectedDashboard.id, layout: JSON.stringify(newLayout)}));
      setLayout(newLayout);
    }
  };

  const onSetShowOnLogin = (checked: boolean) => {
    if (selectedDashboard) {
      void dispatch(setShowOnLogin({dashboardId: selectedDashboard?.id, showOnLogin: checked}));
    }
  };

  const onRemoveDashboard = () => {
    const modalProps: ModalInterface<ConfirmationModalProps> = {
      component: ModalComponentNames.ConfirmationModal,
      props: {
        onClose: () => {
          dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
        },
        onConfirm: () => {
          if (selectedDashboard) {
            void dispatch(deleteDashboard({
              dashboardId: selectedDashboard?.id,
            }));
          }
          dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
        },
        localizations: {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('remove'),
          confirmStyle: 'danger',
          header: (<label>{getLocalization('confirm')}</label>),
          body: (<p>{getLocalization('deleteDashboard')}</p>)
        }
      }
    };
    dispatch(navigateAddModal(modalProps));
  };

  const onRemoveDataPoint = (dashboardId: number, dataPointId: string) => {
    const modalProps: ModalInterface<ConfirmationModalProps> = {
      component: ModalComponentNames.ConfirmationModal,
      props: {
        onClose: () => {
          dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
        },
        onConfirm: () => {
          void dispatch(removeDataPointFromDashboard({
            dashboardId, dataPointId
          }));
          dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
        },
        localizations: {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('remove'),
          confirmStyle: 'danger',
          header: (<label>{getLocalization('confirm')}</label>),
          body: (<p>{getLocalization('removeDataPoint')}</p>)
        }
      }
    };
    dispatch(navigateAddModal(modalProps));
  };

  const children = React.useMemo(() => {
    return dashboardCharts && selectedDashboard ? (
      dashboardCharts?.map(chart => {
        return (
          <div key={`${chart.id}`}>
            <DashboardChart
              chart={chart}
              dashboardId={selectedDashboard.id}
              layout={layout}
              applyFilters={applyFilters}
              canEdit={selectedDashboard.userId === Number(clientPersist.user_id)}
              onChartLoaded={() => setLayout([...layout])}
            />
          </div>
        );
      }).concat(forms.length ? dataPoints.map(dp => (
        <div key={`${dp.id}`} data-grid={layout.find(l => l.i === dp.id)}>
          <div className="col dashboard-chart-container">
            <div className={`row mr-2 justify-content-end`}>
              <Button
                size="sm"
                onClick={() => onRemoveDataPoint(selectedDashboard.id, `${dp.id}`)}
                variant={'danger'}
              >
                <i className="fa fa-trash"/>
              </Button>
            </div>
            <FormComponent
              dataPoint={dp}
              forms={forms}
              clientPersist={clientPersist}
              model={forms.find(f => f.ref === dp.questionnaire_id) || {}}
              readOnly={true}
            />
          </div>
        </div>
      )) : [])
    ) : [];
  }, [dashboardCharts, dataPoints, forms, selectedDashboard, layout]);

  return dashboardMenu.active ? (
    <div className="dashboard-container">
      <Row className="dashboard-container">
        <h5 className="mr-3 dashboard-name">{selectedDashboard?.name}</h5>
        {selectedDashboard?.userId === Number(clientPersist.user_id) && (
          <>
            <Button
              variant="primary"
              size="sm"
              onClick={addChart}
              disabled={dashboardCharts?.length >= 10}
            >
              <i className="fa fa-plus" aria-hidden="true" />
              <span className="add-chart-span">{getLocalization('addChart')}</span>
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={addDataPoint}
              disabled={dashboardCharts?.length >= 10}
            >
              <i className="fa fa-plus" aria-hidden="true" />
              <span className="add-chart-span">{getLocalization('addDataPoint')}</span>
            </Button>
          </>
        )}
        <Button
          size="sm"
          variant={applyFilters ? 'success' : 'secondary'}
          onClick={() => setApplyFilters(!applyFilters)}
        >
          <i className="fa fa-refresh"/>
          <span className="add-chart-span">{getLocalization('apply_filters')}</span>
        </Button>
        <Button
          size="sm"
          variant={'success'}
          onClick={() => resetLayout()}
        >
          <i className="fa fa-refresh"/>
          <span className="add-chart-span">{getLocalization('resetLayout')}</span>
        </Button>
        {selectedDashboard?.userId === Number(clientPersist.user_id) && (
          <>
            <Button
              size="sm"
              onClick={onRemoveDashboard}
              variant={'danger'}
            >
              <i className="fa fa-trash"/>
            </Button>
            <Form.Check
              id="show-after-login-checkbox"
              name={'show-after-login'}
              className="ml-2"
              checked={selectedDashboard?.showOnLogin}
              onChange={(e) => onSetShowOnLogin(e.target.checked)}
              label={getLocalization('showOnLogin')}
            />
          </>
        )}
      </Row>
      <div className="dashboard-container">
        <ResponsiveGridLayout
          cols={{ lg: 2, md: 2, sm: 1, xs: 1, xxs: 1 }}
          layouts={{lg: [...layout], md: [...layout], sm: [...layout]}}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          rowHeight={130}
          isResizable={false}
          onLayoutChange={(layout: ReactGridLayout.Layout[]) => {
            console.log(layout);
            onUpdateLayout(layout);
          }}
          onResizeStop={onResizeStop}
        >
          {children}
        </ResponsiveGridLayout>
      </div>
    </div>
  ) : (
    <p>{getLocalization('selectDashboard')}</p>
  );
}
