import '../styles/QuestionLabel.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { keys, uniq } from 'lodash-es';
import marked from 'marked';
import DOMPurify from 'dompurify';
import { Form } from 'react-bootstrap';
import MoreTextComponent from 'views/Table/MoreTextComponent';
import { LooseObject } from '../../../Interfaces/LooseObject';
import FormUtils from '../utils/FormUtils';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { updateScriptObject } from '../utils/ScriptUtils';
import { hasScript, isNumeric } from '../utils/utils';
import { isNullOrUndefined } from '../../../utils/utils';
// import { Slimdown } from '../utils/Slimdown';
import { questionTypes } from '../../../Interfaces/Forms/QuestionInterface';
import OriginalFormText from './OriginalFormText';

interface Props {
  formUtils: FormUtils;
  dataPoint: DataPoint;
  question: LooseObject;
  children: JSX.Element;
  horizontal?: boolean;
  isTable?: boolean;
}

interface State {
  value: string;
  localSPSSToId: LooseObject;
  obj: LooseObject;
  hasScript: boolean;
}

export default class QuestionLabel extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    let text = props.question.text || '';
    if (text.trim().endsWith('---')) {
      text = text.trim().substring(0, text.length - 3);
    }
    const initialState = {
      value: text,
      localSPSSToId: {},
      obj: {},
      hasScript: hasScript(props.question)
    };
    this.state = this.setObjValues(initialState);
  }

  /*
    When doing the script evaluation, we will need to set the object with _spss -> value mapping.
    This function sets the _spss -> value mapping and also the _spss -> id mapping.
    _spss -> id mapping allows for easier retrieving of values from the data model.
  */
  @bind
  private setObjValues(state: State): State {
    const { formUtils, question } = this.props;
    const localSPSSToId: LooseObject = {}; // mapping for _spss -> id
    const obj = {};
    if (question.text) {
      const allVariables: string[] = uniq(question.text.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));
      for (const v of allVariables) {
        if (v.indexOf('.') !== -1) { // this may be summing a table, a lookup value.
          const prefix = v.substr(0, v.indexOf('.'));
          const questionId = formUtils.getQuestionId(prefix);
          localSPSSToId[v] = questionId;
        } else {
          const id = formUtils.getQuestionId(v);
          localSPSSToId[v] = id;
        }
        obj[v] = '';
      }
    }
    state.localSPSSToId = localSPSSToId;
    state.obj = obj;
    return state;
  }

  public static getDerivedStateFromProps(props: Props, state: State) {
    const { obj, localSPSSToId } = state;
    const { dataPoint, formUtils } = props;
    if (!state.hasScript) {
      return null;
    }

    const response = updateScriptObject(localSPSSToId, obj, formUtils, dataPoint, [], [], []);
    const { updated, updatedObj } = response;
    if (updated) {
      const { question } = props;
      // let script = question.script;
      let text = question.text || '';
      if (text.trim().endsWith('---')) {
        text = text.trim().substring(0, text.length - 3);
      }
      const objKeys = keys(updatedObj);
      for (const key of objKeys) {
        const value = isNullOrUndefined(updatedObj[key]) ? '' : updatedObj[key];
        const renderValue = isNumeric(value) && Number(value) % 1 > 0 ?
          `${Math.round(Number(value) * 100) / 100}` : `${value}`;
        text = text.replace(key, renderValue);
      }
      return { obj : updatedObj, value : text };
    }
    return null;
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value;
  }

  public render(): JSX.Element {
    const { question, isTable, horizontal, formUtils } = this.props;
    const text = DOMPurify.sanitize(marked.parse(this.state.value));

    const more = text.length > 100 && question.type === questionTypes.LABEL && isTable && question.column ? (
      <MoreTextComponent
        question={{...question, text: question.column || ''}}
        value={text}
      />
    ) : null;
    return (
      <Form.Label
        className={`
          ${question.type} ${question.type !== questionTypes.LABEL ? 'question-label' : ''}
        `}
        column={horizontal || undefined}
        sm={horizontal ? 2 : undefined}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: more ? text.substring(0, 100) : text
          }}
        />
        {more}
        {formUtils.getModel().showOriginalLanguage ? (
          <OriginalFormText formId={formUtils.getModel().ref} viewId={question.id} />
        ) : null}
        {this.props.children ? this.props.children : null}
      </Form.Label>
    );
  }
}
