import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'index';
import { StateInterface } from '../../Interfaces/StateInterface';
import { MODULES } from '../../Interfaces/ModuleSelectionInterface';
import { selectTable } from '../../actions/tableActions';
import { selectedFormsSelector } from '../../reducers/formsReducer';

interface Props {
  selectedModule: string;
  buttonInnerJSX: JSX.Element;
  classNames: string;
  onClick: () => void;
}

export type TableButtonProps =  Props;

export default function TableButtonContainer(props: TableButtonProps) {

  const dispatch = useAppDispatch();
  const forms = useAppSelector((state: StateInterface) => selectedFormsSelector(state));
  const selectedTable = useAppSelector((state: StateInterface) => state.tableMenu.selectedTable);

  const onClick = (formId) => {
    if (formId !== selectedTable) {
      dispatch(selectTable(formId));
    }
  };

  return (
    <Dropdown
      id="table-btn"
      onToggle={(isOpen, event, metadata) => {
        if (metadata.source === 'click' && props.selectedModule !== `${MODULES.TABLE}`) {
          props.onClick();
        }
      }}
    >
      <Dropdown.Toggle id="chart-btn-toggle" variant="primary" size={'sm'} className={props.classNames}>
        {props.buttonInnerJSX}
      </Dropdown.Toggle>
      <Dropdown.Menu rootCloseEvent="click" onClick={(e) => e.stopPropagation()}>
        {forms.map((form, index) => {
          return (
            <Dropdown.Item
              as="div"
              eventKey={`${index}`}
              key={`table-dropdown-item-${form.ref}`}
              onClick={() => onClick(form.ref)}
              active={selectedTable === form.ref}
              className={selectedTable === form.ref ? 'table-active' : ''}
            >
              {form.name}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
