import { CHART_DATA_POINTS_ACTIONS } from "actions/actionTypes";
import { ChartDataPointsInterface } from "Interfaces/ChartInterface";

const INITIAL_STATE: ChartDataPointsInterface = {};

const chartDataPoints = (state = INITIAL_STATE, action): ChartDataPointsInterface => {
  switch (action.type) {
    case CHART_DATA_POINTS_ACTIONS.SET:
      if (action.chartDataPoints && action.chartId) {
        return {...state, [action.chartId]: action.chartDataPoints};
      }
      return state;
    default:
      return state;
  }
};

export default chartDataPoints;
